import React from 'react';
import ProductCatalog from './catalog/product/ProductCatalog';
import Navigation from './Navigation';
import TraderTile from './catalog/trader/TraderTile';
import Paper from '@mui/material/Paper';
import { Stack } from '@mui/material';
import store from '../store/store';
import { get_products_range,get_trader,clear_catalog } from '../actions/actions';
import { styled } from '@mui/material/styles';
import InfiniteScroll from "react-infinite-scroll-component";
import { FidgetSpinner,FallingLines } from 'react-loader-spinner';
import { UPDATE_CATALOG_PRODUCTS } from '../constants/action-types';
import { MDBFooter } from 'mdb-react-ui-kit';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

class ProductViewCatalog extends  React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {  
          products_list:[],total_length:4,
          catalog_trader_id:0,
          trader:null,
          is_home_catalog:true,

          load_state:{
            errored:false,
            loading_state_code:0,
            error:"Nothing to report!"
          }
        }
      }
      _isMounted=false;
      componentDidMount() {

        if(this._isMounted===false){
          clear_catalog();
        }
      this._isMounted=true;
        const authResult = new URLSearchParams(window.location.search); 
        const trader_id = authResult.get('trader_id');
        document.title = "Items Catalog.";

        let tid=parseInt(trader_id,10);
        if(tid>0){
            get_trader(tid);
            this.setState({   ...this.state, is_home_catalog:false });
        }else{
          this.setState({   ...this.state, is_home_catalog:true });
        }
        get_products_range(tid,0,0,4);

        store.subscribe(() => {
          // When state will be updated(in our case, when items will be fetched), 
          // we will update local component state and force component to rerender 
          // with new data.
          if (this._isMounted) 
          {

            this.setState({
              ...this.state,
              catalog_trader_id:tid,
              total_length:store.getState().products_catalog.total_count,
              products_list: this.state.products_list.concat(store.getState().products_catalog.products.filter(x => !this.state.products_list.some(y => y.id== x.id))),
            trader:store.getState().trader,
            load_state:store.getState().product_catalog_load_state,
    
          });
        }
        });  
      } 
      componentWillReceiveProps(nextProps) {
     
      }  
      componentWillUnmount() {
     
      }  
    render() {
      let products=this.state.products_list;
      return  (
        this.state.load_state.loading_state_code<2?
        <div>
        <Navigation hidden_search={0}/>
        <div  style={{height: '60vh',display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <FidgetSpinner
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
          ballColors={['#ff0000', '#00ff00', '#0000ff']}
          backgroundColor="#F4442E"
        /><h3>{this.state.load_state.errored?this.state.load_state.error:"Looking for items..."}</h3></div></div>:
      <div>
         <Navigation hidden_search={0}/>
      <div  style={{textAlign:"left"}}><h2>Items</h2></div>
      <Stack>
      <Item>
        {this.state.is_home_catalog===true?<h3>ITEMS BY VARIOUS VENDORS</h3>: this.state.trader===null?"":<TraderTile show_buttons={false} height={"20rem"} width={"100%"} trader={this.state.trader}></TraderTile>}
      </Item>
      <Item> </Item>
      <Item>
         <InfiniteScroll
          scrollableTarget="scrollableDiv"
         dataLength={products.length}
          next={() => { 
            get_products_range(this.state.catalog_trader_id,0,products.length,4);
           }}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>}
          loader={
            <FallingLines
              color="#4fa94d"
              width="100"
              visible={true}
              ariaLabel='falling-lines-loading'
            />
          }
          hasMore={products.length<this.state.total_length}
        >
          <ProductCatalog  id="scrollableDiv" products={products}></ProductCatalog>

        </InfiniteScroll>
      
      </Item>
      </Stack>
      <MDBFooter bgColor='dark' className='text-center text-white text-lg-left'>
        <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          &copy; {new Date().getFullYear()} Copyright:{' '}
          <a className='text-white' href='https://yotakumarket.com/'>
            yotakumarket.com
          </a>
        </div>
      </MDBFooter>
      </div>);
    }
}
export default ProductViewCatalog;
