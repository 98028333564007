// src/js/constants/action-types.js


const SWITH_MODAL_PAGE = 0;
const UPDATE_NAVBAR_PRODUCTS=1;
const UPDATE_NOTIFICATIONS=2;
const GET_INVOICE=3;
const GET_TRADER=4;
const GET_PRODUCTS_TRADER=5;
const GET_PRODUCT =6;
const GET_ORDER =7;
const UPDATE_CATALOG_PRODUCTS=8;
const UPDATE_CATALOG_TRADERS=9;
const GET_USER_INFO=10;
const LOGGED_IN=11;
const GET_MY_ORDERS=12;
const UPDATE_STORE_PRODUCTS=13;
const SET_CURRENT_ORDER_ID=14;
const REQUEST_LOAD_STATUS=15;
const GET_MY_TRANSACTIONS=16;
const CREATE_INVOICE=17;
const GET_TOS=18;
//////////////////////////////////////////////  
export {
  SWITH_MODAL_PAGE,
  UPDATE_NAVBAR_PRODUCTS,
  UPDATE_CATALOG_PRODUCTS,
  UPDATE_CATALOG_TRADERS,
  UPDATE_NOTIFICATIONS,
  GET_INVOICE,
  GET_TRADER,
  GET_PRODUCT,
  GET_PRODUCTS_TRADER,
  GET_ORDER,
  GET_USER_INFO,
  LOGGED_IN,
  GET_MY_ORDERS,
  UPDATE_STORE_PRODUCTS,
  SET_CURRENT_ORDER_ID,
  REQUEST_LOAD_STATUS,
  GET_MY_TRANSACTIONS,
  CREATE_INVOICE,
  GET_TOS
}
