
import React from 'react';
import {withRouter} from './withRouter';
import {withCart} from './withCart';
import axios from "axios";
import {NotificationManager} from 'react-notifications';
import { compose } from 'redux';
import Button from '@mui/material/Button';
import {FidgetSpinner } from 'react-loader-spinner';
import { get_order } from '../actions/actions';
import store from '../store/store';
import CryptoInvoice from './order/invoices/CryptoInvoice';
import OrderPaid from './order/OrderPaid';
import GoodsShipped from './order/GoodsShipped';
import ClientNotPaid from './order/ClientNotPaid';
import ClientPaid from './order/ClientPaid';
import ClientReceived from './order/ClientReceived';
import Dispute from './order/Dispute';
import Completed from './order/Completed';
import Failed from './order/Failed';
import { 
  ORDER_STATUS_NOT_PAID,
  ORDER_STATUS_PAID,
  ORDER_STATUS_SHIPPED,
  ORDER_STATUS_DISPUTE,
  ORDER_STATUS_FAILED,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_CLIENT_NOT_PAID,
  ORDER_STATUS_CLIENT_PAID,
  ORDER_STATUS_CLIENT_SHIPPED
 } from '../constants/order_status';
import OrderPay from './order/OrderPay';

class Order extends  React.Component<any, any>  {
  constructor(props) {
      super(props);
      this.state = {  
       order:null,
       show_payment_page:0,
      }
      this.set_pay_page= this.set_pay_page.bind(this);
    } 
    _isMounted=false;
    set_pay_page(payment_type){
      this.setState({ ...this.state,
        show_payment_page:payment_type,
    })
    }
    interval;
    componentWillUnmount() {
      clearInterval(this.interval);
      console.log("Closed order..");
    }
    componentDidMount() {
      this._isMounted=true;


      const authResult = new URLSearchParams(window.location.search); 
      const order_id = this.props.order_id===null?authResult.get('order_id'):this.props.order_id;
      get_order(parseInt(order_id,10)) ;
      this.interval = setInterval(function() {
      const user_info=store.getState().user_info;
      if(user_info!=null)
      get_order(parseInt(order_id,10)) ;
      console.log("Refreshing order:"+order_id);
    }.bind(this), 10000);
     

      store.subscribe(() => {
        if (this._isMounted) 
        {
          this.setState({
            order:store.getState().order,
        })}
      });
    } 
    render() {
      const authResult = new URLSearchParams(window.location.search); 
      const order_id = this.props.order_id===null?authResult.get('order_id'):this.props.order_id;


    return (
            this.state.order===null?<div  style={{height: '60vh',display: 'flex',  justifyContent:'center', alignItems:'center'}}>
              <FidgetSpinner
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
            ballColors={['#ff0000', '#00ff00', '#0000ff']}
            backgroundColor="#F4442E"
          /><h3>Loading Order #{order_id} ...</h3>
            </div>:
        this.state.order.status_code===ORDER_STATUS_NOT_PAID?this.state.show_payment_page===0?<OrderPay order={this.state.order} set_pay_page={this.set_pay_page}/>:<CryptoInvoice set_pay_page={this.set_pay_page} />:
        this.state.order.status_code=== ORDER_STATUS_CLIENT_NOT_PAID?<ClientNotPaid  order={this.state.order} />:        
       
        this.state.order.status_code===ORDER_STATUS_PAID?< OrderPaid  order={this.state.order} />:
        this.state.order.status_code===ORDER_STATUS_CLIENT_PAID?< ClientPaid  order={this.state.order}  />:

        this.state.order.status_code===ORDER_STATUS_CLIENT_SHIPPED?<GoodsShipped  order={this.state.order} />:
        this.state.order.status_code==ORDER_STATUS_SHIPPED?<ClientReceived   order={this.state.order}  />:

        this.state.order.status_code==ORDER_STATUS_DISPUTE?< Dispute user_info={store.getState().user_info} order={this.state.order} />:
        this.state.order.status_code==ORDER_STATUS_COMPLETED?<Completed order={this.state.order} />:
        this.state.order.status_code==ORDER_STATUS_FAILED?<Failed order={this.state.order} />:
        <div>Nothing here</div>
    );
}
}

export default compose(
  withCart,
  withRouter
)(Order);