import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { purple,green,red,orange} from '@mui/material/colors';
import Alert from '@mui/material/Alert';
import {withRouter} from './withRouter';
import {withCart} from './withCart';
import { compose } from 'redux';
import axios from "axios";
import Navigation from './Navigation';
import {FaAddressBook } from "react-icons/fa";
import { MdMyLocation} from "react-icons/md";
import {BsPersonBoundingBox } from "react-icons/bs";
import {GrCatalog} from "react-icons/gr";
import {GrStatusGood,GrStatusWarning} from "react-icons/gr";
import { FidgetSpinner } from 'react-loader-spinner';
import {NotificationManager} from 'react-notifications';
import store from '../store/store';
import {get_products_trader,get_trader } from '../actions/actions';
import md5 from 'crypto-js/md5';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 16,
    border: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    border: 1,
  },
}));
const NTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    padding:'1px 0px'
  },
}));
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  width:'200px',
  '&:hover': {
    backgroundColor: red[700],
  },
}));

const BuyButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: green[700],
  width:'50px',
  height:'22px',
  '&:hover': {
    backgroundColor:red[700], 
  },
}));

const ViewButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: orange[700],
  width:'50px',
  height:'22px',
  '&:hover': {
    backgroundColor:red[700], 
  },
}));
const ColorButtonGreen = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: green[500],
  width:'200px',
  '&:hover': {
    backgroundColor: red[700],
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    fontSize: 12,
    border: 1,
  },
}));


class TraderInfo extends React.Component<any, any>  {
  constructor(props) {
    super(props);
    this.state = {  products: [] ,trader:null}
    this.handleClick = this.handleClick.bind(this);
  }
  _isMounted=false;
  handleClick(location) {
    this.props.navigate( location); 
  }  
  componentDidMount() {
    this._isMounted=true;

    const authResult = new URLSearchParams(window.location.search); 
    const trader_id = authResult.get('trader_id');
    document.title = "Items Catalog.";

    if(parseInt(trader_id,10)>0){
        get_products_trader(trader_id)
        get_trader(trader_id);
    }
    store.subscribe(() => {
      // When state will be updated(in our case, when items will be fetched), 
      // we will update local component state and force component to rerender 
      // with new data.
      if (this._isMounted) 
      {
        this.setState({
          ...this.state,
        products:store.getState().products_trader,
        trader:store.getState().trader,
      })}
    });
  }
    render(){
      const authResult = new URLSearchParams(window.location.search); 
      const trader_id = authResult.get('trader_id');
      console.log("Trader info:",trader_id);
      return (
        (this.state.products==null||this.state.trader===null)? <div><Navigation hidden_search={0}/>
        <div  style={{textAlign:"left"}}><h2>Trader details</h2></div>
        <div  style={{height: '60vh',display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <FidgetSpinner
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
          ballColors={['#ff0000', '#00ff00', '#0000ff']}
          backgroundColor="#F4442E"
        /><h3>Loading Information...</h3></div></div>:       
   <div>     
<Navigation  hidden_search={0}/>   
<div  style={{textAlign:"left"}}><h2>Trader Info</h2></div>
    <div  style={{width:"100%",textAlign:"center"}}>
  <TableContainer component={Paper}>
    <Table size="small" aria-label="simple table">
    <TableHead>
      <TableRow style={{fontWeight:"bold"}}>
      <TableCell width={200} height={200} align="center" >
        <img alt="PFP" src={"https://www.gravatar.com/avatar/"+md5(this.state.trader.email)+"?s=200"} />
        <ColorButtonGreen onClick={() => { this.handleClick('/chat?trader_id='+ this.state.trader.id) }} size="small" variant="contained" >Chat with Me</ColorButtonGreen>
      </TableCell>
       <TableCell  height={400}  align="right">
        {/*START OF RIGHT TABLE*/}
        <Table size="small" aria-label="simple table">
              <TableHead>
                <StyledTableRow style={{fontWeight:"bold"}}>
                <StyledTableCell  align="right" >Trader Information</StyledTableCell>
                <StyledTableCell align="right">Details</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                    <StyledTableCell style={{fontWeight:"bold"}} align="right">First Name</StyledTableCell>
                    <StyledTableCell align="right">{this.state.trader.fname}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell style={{fontWeight:"bold"}} align="right">Last Name</StyledTableCell>
                    <StyledTableCell align="right">{this.state.trader.lname}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell style={{fontWeight:"bold"}} align="right">Location</StyledTableCell>
                    <StyledTableCell align="right">{this.state.trader.location}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow  >
                    <StyledTableCell style={{fontWeight:"bold"}} align="right">Trade History</StyledTableCell>
                    <StyledTableCell align="right"><GrStatusGood/> {this.state.trader.good_trades} good trades<br></br><GrStatusWarning/> {this.state.trader.bad_trades} bad trades</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell style={{fontWeight:"bold"}} align="right">Total Trades</StyledTableCell>
                    <StyledTableCell align="right">{this.state.trader.good_trades+this.state.trader.bad_trades}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell style={{fontWeight:"bold"}} align="right">Date Registered</StyledTableCell>
                    <StyledTableCell align="right"> {new Date(this.state.trader.date_registered*1000).toLocaleString('en-GB', { timeZone: 'Africa/Nairobi'  })}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell style={{fontWeight:"bold"}} align="right">Last Active</StyledTableCell>
                    <StyledTableCell align="right">{new Date(this.state.trader.last_active*1000).toLocaleString('en-GB', { timeZone: 'Africa/Nairobi'  })}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell style={{fontWeight:"bold"}} align="right">Verification status </StyledTableCell>
                    <StyledTableCell  align="right"  >
                      {/*START OF VERFICATION TABLE*/}
                          <Table style={{fontWeight:"bold"}} size="small" aria-label="simple table">
                            <TableHead>
                              <TableRow >
                              <TableCell align="left" ><BsPersonBoundingBox/> {this.state.trader.id_verified?<font color="green">Identification Verified</font>:<font style={{ textDecorationLine: 'line-through' }} color="red">Identification Not Verified</font>}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="left" ><MdMyLocation/> {this.state.trader.id_verified?<font color="green">Location Verified</font>:<font style={{ textDecorationLine: 'line-through' }} color="red">Location Not Verified</font>}</TableCell>
                              </TableRow>
                              <TableRow >
                                <TableCell align="left"  ><FaAddressBook/> {this.state.trader.id_verified?<font color="green">Address Verified</font>:<font style={{ textDecorationLine: 'line-through' }} color="red">Address Not Verified</font>}</TableCell>
                              </TableRow>
                            </TableHead>
                        </Table>
                      {/*END OF VERFICATION TABLE*/}
                    </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
         {/*END OF INNER TABLE*/}
        </TableCell>
        <TableCell width={700} align="center" >
        <h2>Recent Trade History</h2>
        <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Id</StyledTableCell>
              <StyledTableCell align="left">Item Name</StyledTableCell>
              <StyledTableCell align="left">Destination</StyledTableCell>
              <StyledTableCell align="left">E.T.A</StyledTableCell>
              <StyledTableCell align="left">Trade Status</StyledTableCell>
              <StyledTableCell align="left">Reason</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              { this.state.products!=null &&this.state.products.slice(0, 4).map((current_item, index) => {
                return (
                  <TableRow>
                <NTableCell  align="left">{current_item.id}</NTableCell>
                <NTableCell  align="left">{current_item.name}</NTableCell>
                <NTableCell  align="left">{"Nairobi"}</NTableCell>
                <NTableCell  align="left">{"12hrs"}</NTableCell>
                <NTableCell  align="left">Failed</NTableCell>
                <NTableCell align="left">BOA</NTableCell>
              </TableRow>
                )
            })}
              <TableRow>
                <TableCell colSpan={2} align="left">
                  <ColorButton onClick={() => { this.handleClick('/trade_history?trader_id='+ trader_id) }}   size="small" variant="contained" >More Trade History</ColorButton>
                  </TableCell>
              </TableRow>
              
          </TableBody>
        </Table>
      </TableContainer>
      <h2>Latest Items</h2>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell width={"5px"} >Id</StyledTableCell>
              <StyledTableCell width={"10px"} align="left">Item Name</StyledTableCell>
              <StyledTableCell  align="left">Price</StyledTableCell>
              <StyledTableCell align="left">Date Added</StyledTableCell>
              <StyledTableCell align="left">E.T.A</StyledTableCell>
              <StyledTableCell width={"50px"} align="left"></StyledTableCell>
              <StyledTableCell  width={"50px"} align="left"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          { this.state.products!=null &&this.state.products.slice(0, 3).map((current_item, index) => {
                return (
                  <TableRow>
                  <NTableCell  align="left">{current_item.id}</NTableCell>
                  <NTableCell align="left">{current_item.name}</NTableCell>
                  <NTableCell align="left">{current_item.price}</NTableCell>
                  <NTableCell align="left">{new Date(current_item.time_added*1000).toLocaleString('en-GB', { timeZone: 'Africa/Nairobi'  })}</NTableCell>
                  <NTableCell align="left">{"1 Day"}</NTableCell>
                  <NTableCell align="left"> <BuyButton disabled={this.props.inCart(current_item.id)}  onClick={() => { this.props.addItem(current_item) }}   variant="contained"  size="small" >{this.props.inCart(current_item.id)?"Added":"Add"}</BuyButton></NTableCell>
                  <NTableCell  align="left"> <ViewButton onClick={() => { this.handleClick('/product?product_id='+current_item.id) }}  variant="contained"  size="small" >View</ViewButton></NTableCell>
                </TableRow>
                )
            })}
             
              <TableRow>
                <TableCell colSpan={2}  align="left"> 
                  <ColorButton onClick={() => { this.handleClick('/product_catalog?trader_id='+ trader_id) }} variant="contained"  size="small" ><GrCatalog/>  My Catalog</ColorButton>
                </TableCell>
              </TableRow>
              
          </TableBody>
        </Table>
      </TableContainer>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      </TableBody>
    </Table>
  </TableContainer>
        </div>
        </div>
      );
    }
  }
  export default compose(
    withCart,
    withRouter
  )(TraderInfo);