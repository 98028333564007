import React from 'react';
import ProductTile from './ProductTile';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class ProductCatalog extends  React.Component<any, any> {
    constructor(props) {
        super(props);
    
      }
      
    render() {
        return(
        this.props.products===null?
        <div>Error no products to display!</div>
        :this.props.products.length>0?
        <Row xs={2} md={4} className="m-5" >
        { this.props.products.map((current_item, index) => {
                                            return (
                                              <Col  md={{ span: 3 }} key={index} style={{paddingBottom: 30}}>
                                                {/*from https://stackoverflow.com/a/64650825 
                                                        https://react-bootstrap.github.io/layout/grid/
                                                */}
                                                <ProductTile  id={index} product={current_item}></ProductTile>
                                                  </Col>
                                            )
                                        })} 
        </Row>:<p><h4 style={{color:"red"}}>No products added yet!</h4></p>
        );
        
    }
}
export default ProductCatalog ;
