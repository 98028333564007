import React from 'react';
import { Link } from "react-router-dom";
import Navigation from './components/Navigation';
import axios from "axios";
import TraderCatalog from './components/catalog/trader/TraderCatalog';
import ProductCatalog from './components/catalog/product/ProductCatalog';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import {NotificationManager} from 'react-notifications';
import { FidgetSpinner } from 'react-loader-spinner';
import './App.css';
import store from './store/store';
import { get_products_range, get_traders_range,login} from './actions/actions';
import { purple,green,red,yellow,orange} from '@mui/material/colors';
import {withRouter} from './components/withRouter';
import { MDBFooter } from 'mdb-react-ui-kit';
window.store=store;
window.get_products_range=get_products_range;
window.login=login;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const NTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    padding:'5px 5px'
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: orange[500],
  width:'400px',
  '&:hover': {
    backgroundColor: red[700],
  },
}));

class App extends  React.Component<any, any>  {
  constructor(props) {
      super(props);
      this.handleClick = this.handleClick.bind(this);
      this.state = {  products: [],traders:[] }
    }
    _isMounted=false;
    handleClick(location) {
      this.props.navigate( location); 
    }
    componentDidMount() {
     
      this._isMounted=true;
    store.subscribe(() => {
      // When state will be updated(in our case, when items will be fetched), 
      // we will update local component state and force component to rerender 
      // with new data.
      if (this._isMounted) 
      {
        this.setState({
          ...this.state,
        products:  store.getState().products_catalog.products,
        traders: store.getState().traders_catalog.traders
      });
 
    }
    });  
    get_traders_range(0,3);
    get_products_range(0,0,0,4);
    ///remove below code
    } 
    
    render() {
      return(
        <div><Navigation hidden_search={0}/>{
        this.state.traders.length<1||this.state.products.length<1?
        <div>
        <div  style={{height: '60vh',display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <FidgetSpinner
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
          ballColors={['#ff0000', '#00ff00', '#0000ff']}
          backgroundColor="#F4442E"
        /><h3>Loading Information...</h3></div></div>:
      <div>
    <Stack spacing={2} >
    <Item></Item> 
      <Item><h3 style={{color:"orange"}}>Latest Items</h3> </Item> 
      <Item><ProductCatalog  products={this.state.products}></ProductCatalog>
      <ColorButton  onClick={() => { this.handleClick('/product_catalog' ) }}size="small" variant="contained" >View More Items</ColorButton>
      </Item> 
      <Item> <h3 style={{color:"green"}}>Top Traders</h3></Item> 
      <Item><TraderCatalog  traders={this.state.traders}></TraderCatalog>
      <ColorButton  onClick={() => { this.handleClick('/traders') }}size="small" variant="contained" >View More Traders</ColorButton>
      </Item> 
    </Stack>
     </div>}
     <MDBFooter bgColor='dark' className='text-center text-white text-lg-left'>
      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        &copy; {new Date().getFullYear()} Copyright:{' '}
        <a className='text-white' href='https://yotakumarket.com/'>
          yotakumarket.com
        </a>
      </div>
    </MDBFooter>
     </div>
     
      );
      
  }
  }
export default withRouter(App);