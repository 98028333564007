import { 
  UPDATE_NAVBAR_PRODUCTS,
  UPDATE_NOTIFICATIONS,
  SWITH_MODAL_PAGE,
  GET_INVOICE,
  GET_TRADER,
  GET_PRODUCT, 
  GET_ORDER,
  UPDATE_CATALOG_PRODUCTS,
  UPDATE_CATALOG_TRADERS,
  GET_USER_INFO,
  GET_MY_ORDERS,
  UPDATE_STORE_PRODUCTS,
  SET_CURRENT_ORDER_ID,
  REQUEST_LOAD_STATUS,
  GET_PRODUCTS_TRADER,
  GET_MY_TRANSACTIONS,
  CREATE_INVOICE,
  GET_TOS} from "../constants/action-types";
import fetchClient from "../components/fetchClient";
import store from "../store/store";
import { NotificationManager } from 'react-notifications';


export function get_products_range(user_id,catalog_id,offset,limit) {
  var data={};
  if(user_id!==0)
  data["trader_id"]=user_id;
 
  if(limit!==0){
  data["offset"]=offset;
  data["limit"]=limit;
}

if(catalog_id===0)
data["source"]="catalog";
else if(catalog_id===1)
data["source"]="navbar";
else if(catalog_id===2)
data["source"]="storep";

  fetchClient.post("/get_products.php",data)
    .then(({ data }) => {
    if(catalog_id==0){
    store.dispatch({ type: UPDATE_CATALOG_PRODUCTS, payload:data });
    report_request_status(false,2,"None");
    }else if(catalog_id===1){
    store.dispatch({ type: UPDATE_NAVBAR_PRODUCTS, payload:data.products });
    }
    else if(catalog_id==2){
      store.dispatch({ type: UPDATE_STORE_PRODUCTS, payload:data.products });
    }
  })
  .catch(error => {
    NotificationManager.error(error.message ,"Failed Getting Products");
 });
};
export function clear_navbar(){
  store.dispatch({ type: UPDATE_STORE_PRODUCTS, payload:[] });
}
export function clear_catalog(){
  store.dispatch({ type: UPDATE_CATALOG_PRODUCTS, payload:{products:[],total_items:0} });
}
export function clear_traders_catalog(){
  store.dispatch({ type: UPDATE_CATALOG_TRADERS, payload:{traders:[],total_items:0} });
}
export function get_traders_range(offset,limit) {
  fetchClient.post("/get_traders.php",{offset:offset,limit:limit})
    .then(({ data }) => {
    store.dispatch({ type: UPDATE_CATALOG_TRADERS, payload:data });
    report_request_status(false,2,"None");
  });
};

export function create_new_order(cart_items,emptyCart) {
  fetchClient.post("/create_order.php",cart_items)
    .then(({ data }) => {
      if(data.req_ok){
        emptyCart();
      set_current_order_id(data.order_id);
       set_modal_page(2);
      }else{
      if(data.error_code===13||data.error_code===12){
           remove_token()
          store.dispatch({ type: GET_USER_INFO, payload:null });
        NotificationManager.error(data.error,"Login Again!");
        set_modal_page(3);
       }else{
          NotificationManager.error(data.error,"Getting orders!");
        }
        
      }
      //notify of new order ??
  }) .catch(error => {
    NotificationManager.error(error.message ,"Creating new order");
 });
};
export function update_order_status(order,tracking_info) {
  fetchClient.post("/update_order_status.php",{order,tracking_info})
    .then(({ data }) => {
      if(data.req_ok){
        NotificationManager.info("We have updated order:"+order.id,"Order status updated!");
        get_order(order.id);
        get_my_orders();
      }else{
        if(data.error_code===13||data.error_code===12){
          remove_token()
         store.dispatch({ type: GET_USER_INFO, payload:null });
       NotificationManager.error(data.error,"Login Again!");
       set_modal_page(3);
      }else{
         NotificationManager.error(data.error,"Getting orders!");
       }
      }
      //notify of new order ??
  }) .catch(error => {
    NotificationManager.error(error.message ,"Creating new order");
 });
};
export function set_current_order_id(order_id) {
  store.dispatch({ type:  SET_CURRENT_ORDER_ID, payload:order_id });
};
export function report_request_status(errored,state_code,error) {
  store.dispatch({ type:  REQUEST_LOAD_STATUS, payload:{
    errored:errored,
    loading_state_code:state_code,
    error:error }});
};


export function get_notifications() {
  fetchClient.get("/get_notifications.php")
    .then(({ data }) => {
      if(data.notifications.unseen_orders!==store.getState().notifications.unseen_orders&&data.notifications.unseen_orders!==0){
        NotificationManager.info("You have "+data.notifications.unseen_orders+" Order updates.","Orders updates");
      }else if(data.notifications.product_updates!==store.getState().notifications.product_updates&&data.notifications.product_updates!==0){
        NotificationManager.info("You have "+data.notifications.product_updates+" Product updates.","Products updates");
      }else if(data.notifications.unseen_support_messages!==store.getState().notifications.unseen_support_messages&&data.notifications.unseen_support_messages!==0){
        NotificationManager.info("You have "+data.notifications.unseen_support_messages+" Support updates.","Support updates");
      }else if(data.notifications.unseen_transactions!==store.getState().notifications.unseen_transactions&&data.notifications.unseen_transactions!==0){
        NotificationManager.info("You have "+data.notifications.unseen_transactions+" Transaction updates.","Transaction updates");
      }
    store.dispatch({ type: UPDATE_NOTIFICATIONS, payload:data.notifications });
  }) .catch(error => {
    NotificationManager.error(error.message ,"Getting notifications");
 });
};
export function get_invoice(invoice_id) {
  fetchClient.post('/get_invoice.php',{invoice_id:invoice_id})
    .then(({ data }) => {
      if(data.req_ok)  
    store.dispatch({ type: GET_INVOICE, payload:data.invoice });
    else{
      if(data.error_code===13||data.error_code===12){
        remove_token()
       store.dispatch({ type: GET_USER_INFO, payload:null });
     NotificationManager.error(data.error,"Login Again!");
     set_modal_page(3);
    }else{
       NotificationManager.error(data.error,"Getting orders!");
     }
    }
  }).catch(error => {
    NotificationManager.error(error.message ,"Getting Invoice");
 });
};
export function create_invoice(order_id,invoice_type) {
  fetchClient.post('/create_invoice.php',{order_id:order_id,type:invoice_type})
    .then(({ data }) => {
      if(data.req_ok){  
        store.dispatch({ type: CREATE_INVOICE, payload:data.invoice_id});
          get_invoice(data.invoice_id);
  }
    else{
      if(data.error_code===13||data.error_code===12){
        remove_token()
       store.dispatch({ type: GET_USER_INFO, payload:null });
     NotificationManager.error(data.error,"Login Again!");
     set_modal_page(3);
    }else{
       NotificationManager.error(data.error,"Getting orders!");
     }
    }
  }).catch(error => {
    NotificationManager.error(error.message ,"Getting Invoice");
 });
};

export function set_modal_page(pageid) {
  console.log("Switching to modal:",pageid);
  store.dispatch({ type:  SWITH_MODAL_PAGE, payload:pageid });
};

export function get_trader(trader_id) {
  fetchClient.post('/get_trader.php',{trader_id:trader_id})
    .then(({ data }) => {
    store.dispatch({ type: GET_TRADER, payload:data.trader});
  }).catch(error => {
    NotificationManager.error(error.message ,"Getting trader");
 });
};
export function get_tos() {
  fetchClient.post('/get_tos.php')
    .then(({ data }) => {
      if(data.req_ok){  
        store.dispatch({ type: GET_TOS, payload:data.tos});
  }else{
    NotificationManager.error(data.tos ,"Getting tos"); 
  } 
  }).catch(error => {
    NotificationManager.error(error.message ,"Getting tos");
 });
};
export function get_products_trader(trader_id) {
  var data={};
  data["trader_id"]=trader_id;
  fetchClient.post("/get_products.php",data)
    .then(({ data }) => {
      store.dispatch({ type: GET_PRODUCTS_TRADER, payload:data.products });
  }).catch(error => {
    NotificationManager.error(error.message ,"Getting Products");
 });
};

export function get_product(product_id) {
  fetchClient.post('/get_product.php',{ product_id:product_id})
    .then(({ data }) => {
    store.dispatch({ type: GET_PRODUCT, payload:data.product });
  }).catch(error => {
    NotificationManager.error(error.message ,"Getting Product");
 });
};
export function get_order(order_id) {
  fetchClient.post('/get_order.php',{ order_id:order_id})
    .then(({ data }) => {
      if(data.req_ok) 
    store.dispatch({ type: GET_ORDER, payload:data.order });
    else{
      if(data.error_code===13||data.error_code===12){
        remove_token()
       store.dispatch({ type: GET_USER_INFO, payload:null });
     NotificationManager.error(data.error,"Login Again!");
     set_modal_page(3);
    }else{
       NotificationManager.error(data.error,"Getting orders!");
     }
    }
  }).catch(error => {
    NotificationManager.error(error.message ,"Getting Order");
 });
};
export function get_my_orders() {
  fetchClient.post('/get_my_orders.php')
    .then(({ data }) => {
      if(data.req_ok) 
    store.dispatch({ type: GET_MY_ORDERS, payload:data.orders });
    else{
      if(data.error_code===13||data.error_code===12){
        remove_token()
       store.dispatch({ type: GET_USER_INFO, payload:null });
     NotificationManager.error(data.error,"Login Again!");
     set_modal_page(3);
    }else{
       NotificationManager.error(data.error,"Getting orders!");
     }
    }
  }).catch(error => {
    NotificationManager.error(error.message ,"Getting Orders");
 });
};
export function get_user_info() {
  fetchClient.get("/userinfo.php")
    .then(({ data }) => {
    if(data.req_ok) 
    store.dispatch({ type: GET_USER_INFO, payload:data.user });
    else{
      //maybe automate this on each request ??
      store.dispatch({ type: GET_USER_INFO, payload:null });
      if(data.error_code===13){
           remove_token()
          store.dispatch({ type: GET_USER_INFO, payload:null });
        NotificationManager.error(data.error,"Login Again!");
      }
    }
  }).catch(error => {
    NotificationManager.error(error.message ,"Getting User Info");
 });
};
export function login(email,password) {
  fetchClient.post('/login.php',{
          email:email,password:password}
  )
    .then(({ data }) => {
      if(data.req_ok){
        localStorage.setItem('token',data.token);
        get_user_info();
        NotificationManager.info("You are now signed in","Login Success!");
        set_modal_page(0);
    }else{
      NotificationManager.error(data.error,"Login Failed!");
    }
  }).catch(error => {
    NotificationManager.error(error.message ,"Login failed");
 });
};
export function register(email,password,first_name,last_name) {
  fetchClient.post('/register.php',{
          email:email,password:password,
        first_name:first_name,
        last_name:last_name
        }
  )
    .then(({ data }) => {
      if(data.req_ok){
        NotificationManager.info("You are now registered Click Sign in to continue!","Register Success!",10000);
        set_modal_page(3);
    }else{
      NotificationManager.error(data.error,"Register Failed!");
    }
  }).catch(error => {
    NotificationManager.error(error.message ,"Register failed");
 });
};
export function remove_token(){
  localStorage.removeItem('token');
  localStorage.removeItem('user_info');
}
export function logout() {
  remove_token()
  store.dispatch({ type: GET_USER_INFO, payload:null });
  NotificationManager.info("You have logged out..","Logged out!");
};
export function get_my_transactions() {
  fetchClient.post('/get_my_transactions.php')
    .then(({ data }) => {
      if(data.req_ok) 
    store.dispatch({ type: GET_MY_TRANSACTIONS, payload:data.transactions});
    else{
      if(data.error_code===13||data.error_code===12){
        remove_token()
       store.dispatch({ type: GET_MY_TRANSACTIONS, payload:null });
     NotificationManager.error(data.error,"Login Again!");
     set_modal_page(3);
    }else{
       NotificationManager.error(data.error,"Getting orders!");
     }
    }
  }).catch(error => {
    NotificationManager.error(error.message ,"Getting Orders");
 });
};