import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import Countdown from 'react-countdown';
import Button from 'react-bootstrap/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import {update_order_status} from '../../actions/actions';
import {ORDER_STATUS_SHIPPED,ORDER_STATUS_NOT_PAID} from '../../constants/order_status';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 16,
        border:1
  },
}));
class ClientReceived extends  React.Component<any, any>  {
    constructor(props) {
        super(props);
        this.state = {  
          rating:1,
          order:null,
        }
      } 
      componentDidMount(){
        this.setState({...this.state,
          order:this.props.order,
      });
    }
      render() {
        let CurrentSnipTextAreaSty = {
          border: '1px solid #314929',
          color: 'green',
          fontSize: '1em',
          height: 'calc(50% - 20px)',
          overflowY: 'auto',
          padding: '5px',
          width: 'calc(100% - 12px)',
          resize: 'none'
      };

      const tracking_info=this.state.order!=null?this.state.order.status_codes.find((status) => {
        return status.order_status_code === ORDER_STATUS_SHIPPED;
      }):null;
      const shipping_address=this.state.order!=null?this.state.order.status_codes.find((status) => {
        return status.order_status_code ===ORDER_STATUS_NOT_PAID;
      }):null;

      return (
        this.state.order!=null?
        <Card>
        <Row>
          <Col>
          <div align="center" >
          <Card.Img style={{width:'20rem'}}   variant="left"  src={""} />
          <Card.Text>
          <div align="left" >
                <h4>Order #{this.state.order.id} has been shipped!</h4>
                  Once you receive the items below please confirm reception.
                  <br></br>You should open dispute before the time expires.
                  
                  <h4 style={{color:"red"}}>Shipping details:</h4>
                  <textarea value={shipping_address.order_status_json.shipto} style={CurrentSnipTextAreaSty} disabled={true} cols="48"  rows="5" />
                  
                  <h4 style={{color:"red"}}>Tracking details:</h4>
                  <textarea value={tracking_info.order_status_json.tracking} style={CurrentSnipTextAreaSty} disabled={true} cols="48" maxCols={48} maxRows={5} rows="5" />
                  
                
                  <h6>NB:Incase you fail to respond the seller will be paid and we will assume you received the goods.</h6>
                  </div>
                </Card.Text>
          </div>
          </Col>
          <Col>
            <Card.Body>
            <Card.Title as="h4" style={{color:"green"}}>Items shipped</Card.Title>
                            {/*Table code */}
                            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <StyledTableCell style={{width:"70%"}}  align="right">Item</StyledTableCell>
                      <StyledTableCell  align="right">@</StyledTableCell>
                      <StyledTableCell  align="right">Cost</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {this.state.order.order_data_json.map((item, index) => { return (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <TableCell padding="none" align="right">{item.name}</TableCell>
                      <TableCell padding="none" align="right">{item.quantity}</TableCell>
                      <TableCell padding="none" align="right">{item.itemTotal}</TableCell>
                    </TableRow>);})}
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <TableCell padding="none" align="right"></TableCell>
                      <TableCell padding="none" align="right">Total</TableCell>
                      <TableCell padding="none" align="right">{this.state.order.total_price}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                </TableContainer>
                   {/*Table code end*/}
              </Card.Body>
              <ListGroup className="list-group-flush">
              <ListGroup.Item>
                      <h4 style={{color:"red"}}>You Paid</h4>
                      <h4>{this.state.order.total_price}/-</h4>
              </ListGroup.Item>
              <ListGroup.Item>
                      <Typography component="legend">Rate this transaction</Typography>
                      <Rating name="rating" 
                      max={10} value={this.state.rating}
                       onChange={(event, newValue) => {
                        this.setState({...this.state,
                          rating:newValue,
                      });
                      }}
                      />
                    </ListGroup.Item> 
              <ListGroup.Item>
              Click here to confirm goods are received in good condition.
              <br></br>
              <Button
               onClick={() => { 
                let tracking_info={dispute:false,rating:this.state.rating};
                update_order_status(this.state.order,tracking_info) }}
              >Goods Ok</Button>
              </ListGroup.Item>
              <ListGroup.Item>
                  <h3 style={{color:"red"}}>Time remaining to open dispute.</h3>
                  <h5><Countdown
                      date={(tracking_info.date_created*1000)+((3*24*60*60)*1000)}
                      intervalDelay={0}
                      precision={3}
                      renderer={props =>props.completed?<div>Will close order in</div>: <div>{props.days>0?props.days+" days ":""}{props.hours>0?props.hours+" hours ":""}{props.minutes>0?props.minutes+" minutes  and ":""}{props.seconds>0?props.seconds+" seconds":""}</div>}
                    /></h5>
              Click here incase there is a problem or you did not receive the goods.
              <br></br>
              <Button
               onClick={() => {
                let tracking_info={dispute:true,rating:1};
                update_order_status(this.state.order,tracking_info) }}
              >Open DisPute</Button>
                </ListGroup.Item>
            </ListGroup>
              <Card.Body>
              </Card.Body>
          </Col>
        </Row>
      </Card>:<div></div>);
      }
    }
export default ClientReceived;
              
