import Button from '@mui/material/Button';
import { green, orange, purple, red, yellow } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import axios from "axios";
import React from 'react';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import ListGroup from 'react-bootstrap/ListGroup';
import Nav from 'react-bootstrap/Nav';
import { GrChapterNext, GrChapterPrevious } from "react-icons/gr";
import { FidgetSpinner } from 'react-loader-spinner';
import { NotificationManager } from 'react-notifications';
import { Link } from "react-router-dom";
import { compose } from 'redux';
import { get_product } from '../actions/actions';
import store from '../store/store';
import Navigation from './Navigation';
import { withCart } from './withCart';
import { withRouter } from './withRouter';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import ListGroupItem from 'react-bootstrap/esm/ListGroupItem';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    width:'100px',
    '&:hover': {
      backgroundColor: red[700],
    },
  }));

const BuyButton  = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: green[500],
    width:'150px',
    '&:hover': {
      backgroundColor: red[700],
    },
  }));
  const SerialInput  =styled(TextField)(({ theme }) => ({
    '.MuiInputBase-root': {
      color: "green"
      },
    }));
class Product extends  React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            view_more_info:0,
            nextIcon: <GrChapterNext size={"2em"}/>,
            prevIcon: <GrChapterPrevious size={"2em"}/>
            , product: null ,
            sn:""
          };
          this.handleClick = this.handleClick.bind(this);
      }
      addItem(item){
        var same_trader=true;
        const items=this.props.items;
        let other_vender="";
        for(var key in items){
          if(items[key].trader_id!=item.trader_id){
          same_trader=false;
          other_vender=items[key].trader_name;
          break;
        }
      }
      if(same_trader){
       if(item.product_type===1)
       item.sn=this.state.sn;
        this.props.addItem(item);
        NotificationManager.success("Check your cart if you want to add extra quantities!","Added "+item.name);
      }else{
        NotificationManager.error("Sorry we don't support buying from multiple vendors at the sametime for now.You chose:"+item.trader_name+" Your cart has items from "+other_vender+", you should buy them seperately.","Multiple Suppliers Chosen!");
      }
      }

      _isMounted=false;
      loadData() {
        const authResult = new URLSearchParams(window.location.search); 
        const product_id = authResult.get('product_id');
        get_product(parseInt(product_id,10));
       
      
      } 
      

      handleClick(location) {
        this.props.navigate( location); 
      }
      componentDidMount() {  
        this._isMounted=true;
        document.title = "Yotaku item details";

        this.loadData();

        store.subscribe(() => {
          // When state will be updated(in our case, when items will be fetched), 
          // we will update local component state and force component to rerender 
          // with new data.
          if (this._isMounted) 
          {
            this.setState({
              ...this.state,
            product:store.getState().product
          })}
        });
      }
      componentWillReceiveProps(nextProps) {
        const authResult = new URLSearchParams(window.location.search); 
        const product_id = authResult.get('product_id');
        if (this.state.product.id !==parseInt(product_id,10) ) {
          this.loadData();
        }
      }

    render() {

      let product=this.state.product;
        return (
          product===null?
         <div><Navigation hidden_search={0}/>
          <div  style={{textAlign:"left"}}><h2>Product details</h2></div>
          <div  style={{height: '60vh',display: 'flex',  justifyContent:'center', alignItems:'center'}}>
          <FidgetSpinner
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
            ballColors={['#ff0000', '#00ff00', '#0000ff']}
            backgroundColor="#F4442E"
          /><h3>Loading Item...</h3></div></div>:(
            <div>
            <Navigation hidden_search={0}/>
            <div  style={{textAlign:"left"}}><h2>Product details</h2></div>
            <Card >
            <Card.Body>
            <Card.Title>{product===null?"":product.name}</Card.Title>
            <div align="center" >
            <Carousel nextIcon={this.state.nextIcon} prevIcon={this.state.prevIcon}   fade >
            {product===null?null: product.images.length &&product.images.map((current_item, index) => {
                                            return (
                                                <Carousel.Item key={"Carousel_"+index}>
                                                    <img
                                                     key={"Pimage_"+index} 
                                                    className="d-block w-70"
                                                    src={current_item}
                                                    alt={"Slide #"+index}
                                                    />
                                                    {/*
                                                    <Carousel.Caption>
                                                    <h3 style={{color:"red"}}>{"Slide "+(parseInt(index,10)+1)}</h3>
                                                    
                                                    </Carousel.Caption>
                                                    */}
                                                </Carousel.Item>
                                            )
                                        })}    
             </Carousel>
             <Card.Header>
                <Nav onSelect={(eventKey) => 
                    this.setState({
                        ...this.state,
                        view_more_info: parseInt(eventKey, 10)
                      })
                } variant="pills" defaultActiveKey="0">
                <Nav.Item >
                    <Nav.Link eventKey="0" >Info</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="1" >More Info</Nav.Link>
                </Nav.Item>
                </Nav>
            </Card.Header>
                </div>
            <Card.Text>
                { product===null?"":this.state.view_more_info===0?product.short_description:product.bullet_description}
                </Card.Text>
                <ListGroup className="list-group-flush">
                    <ListGroup.Item><b>Price:</b> {product===null?0:product.price}/-</ListGroup.Item>
                    <ListGroup.Item><b>By:</b> <Link to={product===null?"":'/trader_info?trader_id='+product.trader_id }>{product===null?"":product.trader_name}</Link></ListGroup.Item>
                    <ListGroup.Item><b>Date Added:</b>  {product===null?"N.A":new Date(product.date_created*1000).toLocaleString('en-GB', { timeZone: 'Africa/Nairobi'  })}</ListGroup.Item>
                    {product.product_type===1?<div align="center" ><ListGroup.Item>
                  <Tooltip title={product.product_type===1?"Enter your serial number or imei here to proceed":null}>
                  <SerialInput id="standard-basic" disabled={product.product_type!=1} defaultValue={product.product_type===1?"":product.sn} label={product.product_type===1?"TYPE SN/IMEI":"SN/IMEI"} variant="standard"
                        onChange={(e)=>{
                          this.setState({sn:e.target.value});
                      }}
                        />
                    </Tooltip>
                        </ListGroup.Item>
                        </div>:null}
                        <ListGroupItem> <div align="center" > <BuyButton  disabled={this.props.inCart(product.id)||product.quantity<1||product.product_type===1&&this.state.sn.trim().length<=0} onClick={() => this.addItem(product)} size="small" variant="contained" >{this.props.inCart(product.id)?"Added":product.quantity<1?"Finished":"Add"}</BuyButton></div></ListGroupItem>
                        
                </ListGroup>
                
            </Card.Body>
            </Card>
            </div>)
        );
}
}
export default compose(
    withCart,
    withRouter
  )(Product);
  