import React from 'react';
import { useCart } from "react-use-cart";

export const withCart = (Component) => {
  const Wrapper = (props) => {

    const {
      isEmpty,
      totalUniqueItems,
      items,
      updateItemQuantity,
      removeItem,
      addItem,
      emptyCart,
      inCart,
      getItem,
      cartTotal,
      totalItems,
    } = useCart();
    
    return (
      <Component
        isEmpty={isEmpty} 
        addItem={addItem}
        totalItems={totalItems}
        totalUniqueItems={totalUniqueItems}
        items={items}
        updateItemQuantity={updateItemQuantity}
        removeItem={removeItem}
        emptyCart={emptyCart}
        inCart={inCart}
        getItem={getItem}
        cartTotal={cartTotal}
        {...props}
        />
    );
  };
  
  return Wrapper;
};