import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import Countdown from 'react-countdown';
import Button from 'react-bootstrap/Button';
import store from '../../store/store';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {FidgetSpinner } from 'react-loader-spinner';
import { get_order,create_invoice } from '../../actions/actions';
import {ORDER_STATUS_NOT_PAID} from '../../constants/order_status';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 16,
        border:1
  },
}));
class OrderPay extends  React.Component<any, any>  {
    constructor(props) {
        super(props);
        this.state = {  
        order:null,
        }
      } 
      componentDidMount(){
        store.subscribe(() => {
            this.setState({
              order:store.getState().order,
          })
        });
      }

      render() {
        let CurrentSnipTextAreaSty = {
          border: '1px solid #314929',
          color: 'green',
          fontSize: '1em',
          height: 'calc(50% - 20px)',
          overflowY: 'auto',
          padding: '5px',
          width: 'calc(100% - 12px)',
          resize: 'none'
      };

    const shipping_address=this.state.order!=null?this.state.order.status_codes.find((status) => {
          return status.order_status_code === ORDER_STATUS_NOT_PAID;
      }):null;

      return (
        this.state.order!=null?
        <Card>
        <Row>
          <Col>
          <div align="center" >
          <Card.Img style={{width:'20rem'}}   variant="left"  src={""} />
          <div>
          <div align="left" >
                <h4>Order Details</h4>
                {/*Table code */}
                <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <StyledTableCell style={{width:"70%"}}  align="right">Item</StyledTableCell>
                      <StyledTableCell  align="right">SN</StyledTableCell>
                      <StyledTableCell  align="right">@</StyledTableCell>
                      <StyledTableCell  align="right">Cost</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {this.state.order.order_data_json.map((item, index) => { return (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <TableCell padding="none" align="right">{item.name}</TableCell>
                     {item.product_type===1?<TableCell padding="none" align="right">&nbsp;&nbsp;<font color="red">{item.sn}</font></TableCell>:<TableCell padding="none" align="right"></TableCell>} 
                      <TableCell padding="none" align="right">{item.quantity}</TableCell>
                      <TableCell padding="none" align="right">{item.itemTotal}</TableCell>
                    </TableRow>);})}
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <TableCell padding="none" align="right"></TableCell>
                      <TableCell padding="none" align="right"></TableCell>
                      <TableCell padding="none" align="right">Total</TableCell>
                      <TableCell padding="none" align="right">{this.state.order.total_price}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                </TableContainer>
                   {/*Table code end*/}
                  This order has not been paid for yet, Items will be shipped to your address shown here.
                  
                  <h5>Shipping to:</h5>
                  <textarea value={shipping_address.order_status_json.shipto} style={CurrentSnipTextAreaSty} disabled={true} cols="48" maxCols={48} maxRows={5} rows="5" />
                    <br></br><br></br>
                  <h5>Choose your preffered payment method to move to the next step.</h5>
                  <br></br>
                  </div>
                </div>
          </div>
          </Col>
          <Col>
            <Card.Body>
            <Card.Title as="h3" style={{color:"green"}}>Payment Methods</Card.Title>
              </Card.Body>
              <ListGroup className="list-group-flush">
            </ListGroup>
            <ListGroup.Item>
            <h4 style={{color:"red"}}>Total Ammount {this.state.order.total_price}/-</h4>
                 <Button onClick={() => {
                   create_invoice(this.state.order.id,1);
                   this.props.set_pay_page(1);
                  }}>Binance Pay</Button>&nbsp;&nbsp;
                 <Button disabled>Mpesa Pay</Button>
            </ListGroup.Item>
            <ListGroup.Item>
                <h5 style={{color:"red"}}>Created on: {new Date(this.state.order.date_created* 1000).toLocaleString('en-GB', { timeZone: 'Africa/Nairobi'  })}</h5>
                <h5 style={{color:"red"}}>Expires in:</h5>
                <h5><Countdown
                      date={(this.state.order.date_created*1000)+((1*24*60*60)*1000)}
                      intervalDelay={0}
                      precision={3}
                      onComplete={() => {  get_order(this.state.order.id)}} 
                      renderer={props =>props.completed?<div>Order has expired and cannot be paid for.</div>: <div>{props.days>0?props.days+" days ":""}{props.hours>0?props.hours+" hours ":""}{props.minutes>0?props.minutes+" minutes  and ":""}{props.seconds>0?props.seconds+" seconds":""}</div>}
                    /></h5>
              </ListGroup.Item>
              <Card.Body>
              </Card.Body>
          </Col>
        </Row>
      </Card>:<FidgetSpinner
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
            ballColors={['#ff0000', '#00ff00', '#0000ff']}
            backgroundColor="#F4442E"
          />);
      }
    }
export default OrderPay;
              
