import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';

import Countdown from 'react-countdown';
import {ORDER_STATUS_SHIPPED} from '../../constants/order_status';
class GoodsShipped extends  React.Component<any, any>  {
    constructor(props) {
        super(props);
        this.state = {  
        order:null,
        service_fee_rate:0.1
        }
      } 
      componentDidMount(){
        this.setState({
          order:this.props.order,
      });
    }
      render() {

        const shipping_date=this.state.order!=null?this.state.order.status_codes.find((status) => {
          return status.order_status_code === ORDER_STATUS_SHIPPED;
        }):null;
      return (
        this.state.order!=null?
        <Card>
        <Row>
          <Col>
          <div align="center" >
          <Card.Img style={{width:'20rem'}}   variant="left"  src={""} />
          <Card.Text>
          <div align="left" >
                <h4>Waiting for Buyer</h4>
                  Please wait for the buyer to confirm goods received.
                  <br></br>Your payment will be released as soon as the buyer confirms received with no disputes.
                  <h5>NB:Incase buyer disputes, payment will be halted untill dispute is resolved..</h5>
                  </div>
                </Card.Text>
          </div>
          </Col>
          <Col>
            <Card.Body>
            <Card.Title as="h4" style={{color:"green"}}>Details about your payment</Card.Title>
              </Card.Body>
              <ListGroup className="list-group-flush">
              <ListGroup.Item>
                      <h4 style={{color:"red"}}>Your will receive:</h4>
                      <h4>{this.state.order.total_price-(this.state.order.total_price*this.state.service_fee_rate)} /-</h4>
                      <h4 style={{color:"red"}}>Client Paid:</h4>
                      <h4>{this.state.order.total_price}/-</h4>
                      <h4 style={{color:"red"}}>Service Fee:</h4>
                      <h4>{this.state.order.total_price*this.state.service_fee_rate}/- Or {this.state.service_fee_rate*100}%</h4>
              </ListGroup.Item>
              <ListGroup.Item>
                  <h4 style={{color:"red"}}>Time Untill Payment</h4>
                  <h5><Countdown
                      date={(shipping_date.date_created*1000)+((2*24*60*60)*1000)}
                      intervalDelay={0}
                      precision={3}
                      renderer={props =>props.completed?<div>Payment was released</div>: <div>{props.days>0?props.days+" days ":""}{props.hours>0?props.hours+" hours ":""}{props.minutes>0?props.minutes+" minutes  and ":""}{props.seconds>0?props.seconds+" seconds":""}</div>}
                    /></h5>
                </ListGroup.Item>
            </ListGroup>
              <Card.Body>
              </Card.Body>
          </Col>
        </Row>
      </Card>:<div></div>);
      }
    }
export default GoodsShipped ;
              
