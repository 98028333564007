import React, { useState } from "react"
import './Auth.css';
import { login ,register,get_tos} from '../actions/actions';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { purple,green,red,yellow} from '@mui/material/colors';
import store from "../store/store";
import PasswordStrengthBar from 'react-password-strength-bar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  '&:hover': {
    backgroundColor: red[700],
  },
  '&:disabled': {
    backgroundColor: green[700],
    color:yellow[700]
  },
}));

class Auth extends React.Component<any, any>  {
  _isMounted=false;
  constructor(props) {
    super(props);
    this.state = {
      authMode:1,
      email:"",password:"",
      first_name:"",last_name:"",
      pass_valid:false,
      accepted_tos:false,
      tos:null
     }
     this.changeAuthMode = this.changeAuthMode.bind(this);
     this.login_clicked=this.login_clicked.bind(this);
     this.register_clicked=this.register_clicked.bind(this);
     this._isMounted =false;
    }
    isValidEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
    }
    changeAuthMode(){
      if(this.state.authMode===1){
      this.setState({  ...this.state,authMode:2 });
      console.log("Register mode!");
      }else if(this.state.authMode===2){
      this.setState({  ...this.state,authMode:1 });
      console.log("Login mode!");
    }
    }
    login_clicked(){
     login(this.state.email,this.state.password);
    }
    register_clicked(){
      register(this.state.email,this.state.password,this.state.first_name,this.state.last_name);
    }
    
  componentDidMount() {
    this._isMounted = true;
    get_tos();
    store.subscribe(() => {
      if (this._isMounted) 
      {
        this.setState({
          tos:store.getState().tos,
      })}
    });
  }
  change_checkbox = (e) => {
    this.setState({
      ...this.state,
     accepted_tos:e.target.checked,
    });
  }
    render() {

  if (this.state.authMode === 1) {
    return (
      <div className="Auth-form-container">
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Sign In</h3>
            <div className="text-center">
              Not registered yet?{" "}
              <span className="link-primary" onClick={this.changeAuthMode}>
                Sign Up
              </span>
            </div>
            <div className="form-group mt-3">
              <label>Email address</label>
              <input
                type="email"
                className="form-control mt-1"
                placeholder="Enter email"
                value={this.state.email}
            
              onChange={(newValue) => {
                this.setState({
                  ...this.state,
                  email:newValue.target.value
              });
              }}

              />
            </div>
            <div className="form-group mt-3">
              <label>Password</label>
              <input
                type="password"
                value={this.state.passwrod}
                className="form-control mt-1"
                placeholder="Enter password"
                
                onChange={(newValue) => {
                  this.setState({
                    ...this.state,
                    password: newValue.target.value
                });
                }}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <ColorButton disabled={!this.isValidEmail(this.state.email)||this.state.password.length<3} className="btn btn-primary"
               onClick={() => { this.login_clicked() }}>
                Submit
              </ColorButton>
            </div>
            <p className="text-center mt-2">
              Forgot <a href="#">password?</a>
            </p>
          </div>
        </form>
      </div>
    )
  }else if (this.state.authMode === 2 && this.state.accepted_tos){
  return (
    <div className="Auth-form-container">
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign Up</h3>
          <div className="text-center">
            Already registered?{" "}
            <span className="link-primary" onClick={this.changeAuthMode}>
              Sign In
            </span>
          </div>
          <div className="form-group mt-3">
            <label>First Name</label>
            <input
              type="text" name="name"
              value={this.state.first_name}
              className="form-control mt-1"
              placeholder="e.g Jane"
              onChange={(newValue) => {
                this.setState({
                  ...this.state,
                  first_name:newValue.target.value
              });
              }}
            />
               <label>Last Name</label>
            <input
             type="text" name="lastname"
              className="form-control mt-1"
              placeholder="e.g Doe"
              value={this.state.last_name}
              onChange={(newValue) => {
                this.setState({
                  ...this.state,
                  last_name:newValue.target.value
              });
              }}
            />
          </div>
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
          value={this.state.email}
            onChange={(newValue) => {
              this.setState({
                ...this.state,
                email:newValue.target.value
            });
            }}
              type="email"
              className="form-control mt-1"
              placeholder="Email Address"
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
                value={this.state.password}
             onChange={(newValue) => {
               this.setState({
                 ...this.state,
                 password: newValue.target.value
             });
             }}
              type="password"
              className="form-control mt-1"
              placeholder="Password"
            />
            <br></br>
            <PasswordStrengthBar onChangeScore={(score,feedback) => {
             
               this.setState({
                 ...this.state,
                 pass_valid:score>3
             });
             }} password={this.state.password} />
          </div>
          <div className="d-grid gap-2 mt-3">
            <ColorButton  disabled={!this.state.pass_valid||!this.isValidEmail(this.state.email) ||this.state.first_name.length<3||this.state.last_name.length<3} className="btn btn-primary"
               onClick={() => { this.register_clicked() }}>
                Submit
              </ColorButton>
          </div>
          <p className="text-center mt-2">
            Forgot <a href="#">password?</a>
          </p>
        </div>
      </form>
    </div>
  )}else{
    return (
     <div>
      <center><b>YOTAKU MARKET TERMS OF USE</b></center>
      <b>LAST UPDATED:</b>{new Date(this.state.tos.date_created* 1000).toLocaleString('en-GB', { timeZone: 'Africa/Nairobi'  })}
      <br></br>
      <textarea value={this.state.tos.tos_string}  disabled={true} cols="85" maxCols={20}  rows="10" />
      <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table" >
                  <TableHead>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell   style={{width:"50%"}} align="left"></TableCell>
                      <TableCell  align="right"></TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border:0 } }}>
                      <TableCell padding="none" align="left"></TableCell>
                      <TableCell padding="none" align="left">
                      <FormControlLabel
                        value="top"
                        control={<Checkbox   checked={this.state.accepted_tos} onChange={this.change_checkbox} defaultChecked color="secondary" />}
                        label="Accept terms and conditions to register"
                        labelPlacement="start"
                      />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                </TableContainer>
      </div>
    )
  }
}
}
export default Auth ;