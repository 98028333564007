import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import Countdown from 'react-countdown';
import {ORDER_STATUS_NOT_PAID} from '../../constants/order_status';
class ClintNotPaid extends  React.Component<any, any>  {
    constructor(props) {
        super(props);
        this.state = {  
          order:null
        }
      } 
      componentDidMount(){
        this.setState({
          order:this.props.order,
      });
    }

      render() {

        const shipping_address=this.state.order!=null?this.state.order.status_codes.find((status) => {
          return status.order_status_code === ORDER_STATUS_NOT_PAID;
        }):null;

      return (
        this.state.order!=null?
        <Card>
        <Row>
          <Col>
          <div align="center" >
          <Card.Img style={{width:'20rem'}}   variant="left"  src={""} />
          <Card.Text>
          <div align="left" >
                <h4>Waiting for Buyer to Pay</h4>
                  Please wait for the customer to Pay.
                  <br></br>This order will update immediately after the customer pays.
                  <h5>NB:Incase customer fails to pay this order will be cancelled automatically..</h5>
                  </div>
                </Card.Text>
          </div>
          </Col>
          <Col>
            <Card.Body>
            <Card.Title as="h4" style={{color:"green"}}>Details about the order</Card.Title>
              </Card.Body>
              <ListGroup className="list-group-flush">
              <ListGroup.Item>
                  <h3 style={{color:"red"}}>Time Untill Expiry</h3>
                  <h5><Countdown
                      date={(shipping_address.date_created*1000)+((1*24*60*60)*1000)}
                      intervalDelay={0}
                      precision={3}
                      renderer={props =>props.completed?<div>Order was cancelled</div>: <div>{props.days>0?props.days+" days ":""}{props.hours>0?props.hours+" hours ":""}{props.minutes>0?props.minutes+" minutes  and ":""}{props.seconds>0?props.seconds+" seconds":""}</div>}
                    /></h5>
                </ListGroup.Item>
            </ListGroup>
              <Card.Body>
              </Card.Body>
          </Col>
        </Row>
      </Card>:<div></div>);
      }
    }
export default ClintNotPaid;
              
