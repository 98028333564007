import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import {NotificationContainer} from 'react-notifications';
import 'bootstrap/dist/css/bootstrap.min.css';
import TraderInfo from './components/TraderInfo'
import MyAccount from './components/MyAccount'
import ProductViewCatalog  from './components/ProductViewCatalog';
import TraderViewCatalog  from './components/TraderViewCatalog';
import Product  from './components/Product';
import { CartProvider} from "react-use-cart";
import Auth from './components/Auth';
import Orders from './components/Orders';
import Order from './components/Order';
import Transactions from './components/Transactions';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CartProvider> 
  <BrowserRouter>
  <Routes>
    <Route path="/" element={<App />} />
    <Route path="trader_info" element={<TraderInfo />} />
    <Route path="account" element={< MyAccount />} />
    <Route path="product_catalog" element={< ProductViewCatalog />} />
    <Route path="traders" element={< TraderViewCatalog />} />
    <Route path="product" element={< Product />} />
    <Route path="auth" element={< Auth />} />
    <Route path="orders" element={< Orders />} />
    <Route path="order" element={< Order/>} />
    <Route path="transactions" element={< Transactions/>} />
  </Routes>
</BrowserRouter>
<NotificationContainer/>
</CartProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
