
const ORDER_STATUS_NOT_PAID=1;
const ORDER_STATUS_PAID=2;
const ORDER_STATUS_SHIPPED=3;
const ORDER_STATUS_DISPUTE=4;
const ORDER_STATUS_FAILED=5;
const ORDER_STATUS_COMPLETED=6;
const ORDER_STATUS_CLIENT_NOT_PAID=7;
const ORDER_STATUS_CLIENT_PAID=8;
const ORDER_STATUS_CLIENT_SHIPPED=9;
//////////////////////////////////////////////  
export {
 ORDER_STATUS_NOT_PAID,
 ORDER_STATUS_PAID,
 ORDER_STATUS_SHIPPED,
 ORDER_STATUS_DISPUTE,
 ORDER_STATUS_FAILED,
 ORDER_STATUS_COMPLETED,
 ORDER_STATUS_CLIENT_NOT_PAID,
 ORDER_STATUS_CLIENT_PAID,
 ORDER_STATUS_CLIENT_SHIPPED

  }