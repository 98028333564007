import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {withRouter} from './withRouter';
import {withCart} from './withCart';
import { TiShoppingCart }   from "react-icons/ti";
import {VscAccount} from "react-icons/vsc";
import {GrTransaction,GrChatOption} from "react-icons/gr";
import { MdOutlineSupportAgent,MdOutlineLogout,MdOutlineLogin } from "react-icons/md";
import {IoBagCheckOutline}from "react-icons/io5";
import {CgReorder}  from "react-icons/cg";
import Marquee from "react-fast-marquee";
import axios from "axios";
import { compose } from 'redux';
import Cart from './Cart';
import Modal from 'react-bootstrap/Modal';
import Auth from './Auth';
import Order from './Order';
import store from '../store/store';
import { get_products_range,get_notifications,set_modal_page,logout,get_user_info,clear_navbar  } from '../actions/actions';
class Navigation extends React.Component<any, any>  {
  _isMounted=false;
  constructor(props) {
    super(props);
    this.state = {
     hide_search:false,
     hide_mq:false, 
     nav_bar_products: [] ,
     user_info:null,
     notifications:{
      unseen_orders:0,
      unseen_transactions:0,
      unseen_support_messages:0,
      product_updates:0
     },
     order_id:0
    };
    this.handleClick = this.handleClick.bind(this);
    this.enableCheckout = this.enableCheckout.bind(this);
  }
  interval;
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  componentDidMount() {
    


    this.interval = setInterval(function() {
      const user_info=store.getState().user_info;
      if(user_info!=null)
      get_notifications();
      console.log("Notification check");
    }.bind(this), 10000);

    if(this._isMounted===false){
      clear_navbar();
    }
    this._isMounted = true;
    let hide_search=this.props.hidden_search===1;
    let hide_mq=this.props.hide_mq===1;
      this.setState({
        ...this.state,
        hide_search:hide_search,
        hide_mq:hide_mq,
        current_modal_page:0
      });
      get_products_range(0,1,0,10);
      const user_info=store.getState().user_info;
      if(user_info!=null){
      get_notifications();
      get_user_info();
    }
     
        store.subscribe(() => {
          // When state will be updated(in our case, when items will be fetched), 
          // we will update local component state and force component to rerender 
          // with new data.
          if (this._isMounted) 
          {
            this.setState({
              ...this.state,
              notifications:  store.getState().notifications,
              nav_bar_products: store.getState().nav_bar_products ,
              current_modal_page:store.getState().current_modal_page,
              user_info:store.getState().user_info,
              order_id:store.getState().current_order_id
          })
        }
        });


  }
  enableCheckout(){
    console.log("Checkout clicked!");
    this.setState({
      ...this.state,
      current_modal_page:2
    });
  }
  handleClick(location) {
    console.log("Heading to:",location);
    this.props.navigate( location); 
  }
  render() {
    let modal_content;
    if(this.state.current_modal_page===1)
    modal_content=<div><Modal.Header closeButton onClick={() => { set_modal_page(0) }}>
             <Modal.Title>Your <TiShoppingCart/> cart</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {this.props.isEmpty? <h1 className="text-center"> Your cart isEmpty </h1>:
           <Cart show_modal_id={set_modal_page}   enableCheckout={this.enableCheckout}/>}
           </Modal.Body></div>;
    else if(this.state.current_modal_page===2) 
    modal_content=<div><Modal.Header closeButton onClick={() => { set_modal_page(0) }}>
        <Modal.Title>Order id #{this.state.order_id}  information</Modal.Title>
      </Modal.Header>
      <Order set_modal_page={set_modal_page} order_id={this.state.order_id}/>
      </div>;


  else if(this.state.current_modal_page===3) 
  modal_content=<div><Modal.Header closeButton onClick={() => { set_modal_page(0) }}>
      <Modal.Title>Login/Signup</Modal.Title>
    </Modal.Header>
    < Auth />
    </div>;

  return (
    <div>
    <Navbar bg="dark" variant="dark" key={"md"}  expand={"md"} className="mb-3">
          <Container fluid>
          <Navbar.Brand  onClick={() => { this.handleClick('/') }}>
            <span> <font size={"6px"} type="" color="white"><b>Yotaku Market</b></font>
            <font size={"6px"} color="red"> Crypto escrow market for parts</font></span>
          </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-md`}
              aria-labelledby={`offcanvasNavbarLabel-expand-md`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                  Offcanvas
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link onClick={() => { set_modal_page(1) }} ><TiShoppingCart/> Shopping Cart ({this.props.totalUniqueItems}) |</Nav.Link>
                  <NavDropdown
                    title={(this.state.user_info!=null)?this.state.user_info.email+" ("+(this.state.notifications.unseen_orders+this.state.notifications.unseen_transactions+this.state.notifications.unseen_support_messages+this.state.notifications.product_updates)+")":"Login|Register"}
                    id={`offcanvasNavbarDropdown-expand-md`}
                  >
                   { this.state.user_info!=null?<div>
                    <NavDropdown.Item onClick={() => { this.handleClick('/account') }} ><VscAccount/> Edit Profile</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => { this.handleClick('/transactions') }}><GrTransaction/> Transactions{this.state.notifications.unseen_transactions>0?" ("+this.state.notifications.unseen_transactions+")":""}</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => { this.handleClick('/orders') }}><CgReorder/> Orders{this.state.notifications.unseen_orders>0?" ("+this.state.notifications.unseen_orders+")":""}</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => { this.handleClick('/chat') }}><GrChatOption/> Products{this.state.notifications.product_updates>0?" ("+this.state.notifications.product_updates+")":""}</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => { this.handleClick('/chat') }}><MdOutlineSupportAgent/> Support{this.state.notifications.unseen_support_messages>0?" ("+this.state.notifications.unseen_support_messages+")":""}</NavDropdown.Item>
                    </div> :<div></div>}
                    <NavDropdown.Divider />
                    { this.state.user_info!=null?
                    <NavDropdown.Item  onClick={() => {  this.handleClick('/'); logout(); }}><MdOutlineLogout/> Logout</NavDropdown.Item>:
                    <NavDropdown.Item  onClick={() => { set_modal_page(3) }}><MdOutlineLogin/> Login|Register</NavDropdown.Item>}
                  </NavDropdown>
                </Nav>
                <div hidden={this.state.hide_search}>
                <Form className="d-flex" >
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-success">Search</Button>
                </Form>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        <div hidden={this.state.hide_mq}>
           <Marquee  gradientWidth={700} gradient={false} gradientColor={ [0, 255, 0]} pauseOnHover={true} speed={30}>
           { this.state.nav_bar_products!=null ?this.state.nav_bar_products.map((current_item, index) => {
                     return (
                       <div key={"MQ_"+index} onClick={() => { this.handleClick('/product?product_id='+current_item.id) }}>
                        <img height={"20px"} width={"20px"} src={current_item.images[0]}></img> 
                        <span style={{color:"red"}}><b> {current_item.name}</b> </span>
                        <span style={{color:"green"}}> <i>{current_item.price}/-</i> </span>
                        <span>By {current_item.trader_name} /-</span>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;</span>
                       </div> 
                     )
                 }):<h3>loading...</h3>}
           </Marquee>
           </div>
           <div>
           <Modal size={"lg"} show={this.state.current_modal_page>0} >
            {modal_content}
            <Modal.Footer>
            </Modal.Footer>
          </Modal>
           </div>
           </div>
  );
}
}
export default compose(
  withCart,
  withRouter
)(Navigation);