import { 
  UPDATE_NOTIFICATIONS,
  UPDATE_NAVBAR_PRODUCTS,
  SWITH_MODAL_PAGE,
  GET_INVOICE,
  GET_TRADER,
  GET_PRODUCTS_TRADER,
  GET_PRODUCT,
  GET_ORDER,
  UPDATE_CATALOG_PRODUCTS,
  UPDATE_CATALOG_TRADERS,
  GET_USER_INFO,
  GET_MY_ORDERS,
  UPDATE_STORE_PRODUCTS,
  SET_CURRENT_ORDER_ID,
  REQUEST_LOAD_STATUS,
  GET_MY_TRANSACTIONS,
  CREATE_INVOICE,
  GET_TOS
} from "../constants/action-types";



const user_info = localStorage.getItem('user_info')? JSON.parse(localStorage.getItem('user_info')): null;
  
const initialState = {
  notifications:{
    unseen_orders:0,
    unseen_transactions:0,
    unseen_support_messages:0,
    product_updates:0
   },
   nav_bar_products:[],
   products_catalog:{products:[],total_length:0},
   traders_catalog:{traders:[],total_length:0},
   store_products:[],
   current_modal_page:0,
   transactions:null,
   invoice:null,
   trader:null,
   product:null,
   order:null,
   orders:null,
   current_order_id:0,
   user_info:user_info ,
   invoice_id:null,
   product_catalog_load_state:{
    errored:false,
    loading_state_code:0,
    error:"Nothing to report!"
  },
  tos:null
};

function reducer(state = initialState, action) {

  if (action.type ===  UPDATE_NAVBAR_PRODUCTS) {
    return Object.assign({}, state, 
      { nav_bar_products: action.payload}
    );
  }else if (action.type === UPDATE_CATALOG_PRODUCTS) {
    return Object.assign({}, state, 
      { products_catalog: action.payload}
    );
  }else if (action.type === UPDATE_CATALOG_TRADERS) {
    return Object.assign({}, state, 
      { traders_catalog: action.payload}
    );
  }
  else if(action.type ===  SWITH_MODAL_PAGE) {
    return Object.assign({}, state, 
      { current_modal_page: action.payload}
    );
  }else if(action.type ===  UPDATE_NOTIFICATIONS) {
    return Object.assign({}, state, 
      { notifications: action.payload}
    );
  }else if(action.type === GET_INVOICE) {
    return Object.assign({}, state, 
      { invoice: action.payload}
    );
  }
  else if(action.type ===  GET_TRADER) {
    return Object.assign({}, state, 
      { trader: action.payload}
    );
  }
  else if(action.type === GET_PRODUCTS_TRADER) {
    return Object.assign({}, state, 
      { products_trader: action.payload}
    );
  }
  else if(action.type === GET_PRODUCT) {
    return Object.assign({}, state, 
      { product: action.payload}
    );
  }
  else if(action.type === GET_ORDER) {
    return Object.assign({}, state, 
      { order: action.payload}
    );
  }
    else if(action.type ===  GET_USER_INFO) {
      localStorage.setItem('user_info',JSON.stringify(action.payload));
      return Object.assign({}, state, 
        { user_info: action.payload}
      );
  }
  else if(action.type ===  GET_MY_ORDERS) {
    return Object.assign({}, state, 
      { orders: action.payload}
    );
}
else if(action.type ===  UPDATE_STORE_PRODUCTS) {
  return Object.assign({}, state, 
    { store_products: action.payload}
  );
}
else if(action.type ===  SET_CURRENT_ORDER_ID) {
  return Object.assign({}, state, 
    { current_order_id: action.payload}
  );
}
else if(action.type === REQUEST_LOAD_STATUS) {
  return Object.assign({}, state, 
    {product_catalog_load_state: action.payload}
  );
}
else if(action.type === GET_MY_TRANSACTIONS) {
  return Object.assign({}, state, 
    {transactions: action.payload}
  );
}else if(action.type === CREATE_INVOICE) {
  return Object.assign({}, state, 
    {invoice_id: action.payload}
  );
}else if(action.type === GET_TOS) {
  return Object.assign({}, state, 
    {tos: action.payload}
  );
}

  return state;
}

export default reducer;
