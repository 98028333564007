import React from 'react';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { purple,green,red,yellow,orange,blue} from '@mui/material/colors';
import {withRouter} from '../../withRouter';
import {withCart} from '../../withCart';
import { compose } from 'redux';
import Tooltip from '@mui/material/Tooltip';
import 'react-notifications/lib/notifications.css';
import {NotificationManager} from 'react-notifications';
import TextField from '@mui/material/TextField';
import { MdOutlineLogin } from "react-icons/md";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(blue[500]),
    width:'50%',
    backgroundColor: yellow[700],
    '&:hover': {
      backgroundColor: red[700],
    },
  }));
const BuyButton  = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: green[500],
    width:'50%',
    '&:hover': {
      backgroundColor: red[700],
    },
  }));

  const SerialInput  =styled(TextField)(({ theme }) => ({
    '.MuiInputBase-root': {
      color: "green"
      },
    }));
class ProductTile extends  React.Component<any, any>  {
    constructor(props) {
        super(props);
        this.state = {  
          sn:""
        }
        this.handleClick = this.handleClick.bind(this);
        this.addItem= this.addItem.bind(this);
      }
      handleClick(location) {
        this.props.navigate( location); 
      }

      componentDidMount() {
    
      }
      addItem(item){
        var same_trader=true;
        const items=this.props.items;
        let other_vender="";
        for(var key in items){
          if(items[key].trader_id!=item.trader_id){
          same_trader=false;
          other_vender=items[key].trader_name;
          break;
        }
      }
      if(same_trader){
       if(item.product_type===1)
       item.sn=this.state.sn;
        this.props.addItem(item);
        NotificationManager.success("Check your cart if you want to add extra quantities!","Added "+item.name);
      }else{
        NotificationManager.error("Sorry we don't support buying from multiple vendors at the sametime for now.You chose:"+item.trader_name+" Your cart has items from "+other_vender+", you should buy them seperately.","Multiple Suppliers Chosen!");
      }
      }
    render() {
      let product=this.props.product;
        return (
          product!=null?
            <div >
      <Card sx={{ maxWidth: 345 ,maxHeight:500,height:500,border:1 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        <Link  to={'/product?product_id='+product.id} >
        {product.name}
        </Link>
        </Typography>
        <CardMedia
        sx={{ height: 200 }}
        image={product.images[0]}
        title={product.name}
      />
        <Typography variant="body2" color="text.secondary">
       {product.short_description} 
        </Typography>
        <Typography gutterBottom variant="h5" component="div">
        {product.price}/-
        </Typography>
        <Typography gutterBottom variant="h7" component="div">
        <b>SKU:</b>{product.sku.toUpperCase()}<b> By:</b> <Link to={'/trader_info?trader_id='+product.trader_id }>{product.trader_name}</Link>
        </Typography>
      </CardContent>
      {product.product_type===1?
                  <Tooltip title={product.product_type===1?"Enter your serial number or imei here to proceed":null}>
                  <SerialInput id="standard-basic" disabled={product.product_type!=1} defaultValue={product.product_type===1?"":product.sn} label={product.product_type===1?"TYPE SN/IMEI":"SN/IMEI"} variant="standard"
                        onChange={(e)=>{
                          this.setState({sn:e.target.value});
                      }}
                        />
                    </Tooltip>:<div style={{height:"3rem"}}></div>}
      <CardActions>
        <ColorButton  disabled={this.props.inCart(product.id)||product.quantity<1||product.product_type===1&&this.state.sn.trim().length<=0} onClick={() => this.addItem(product)} size="small" variant="contained" >{this.props.inCart(product.id)?"Added":product.quantity<1?"Finished":"Add"}</ColorButton>
        <BuyButton  onClick={() => { this.handleClick('/product?product_id='+ product.id) }}size="small" variant="contained" >View</BuyButton>     
      </CardActions>
     
    </Card>
            </div>:<div></div>
        )
    }
}
export default compose(
  withCart,
  withRouter
)(ProductTile);