import  { configureStore }  from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import reducer from "../reducer/reducer";


const middleware = [thunk]

const store = configureStore({
    reducer,
  /*preLoadedState,*/
  middleware,
})

export default store;