import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import Countdown from 'react-countdown';
import TextField from '@mui/material/TextField';
import Button from 'react-bootstrap/Button';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {ORDER_STATUS_NOT_PAID} from '../../constants/order_status';
import {update_order_status} from '../../actions/actions';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 16,
        border:1
  },
}));



class ClientPaid extends  React.Component<any, any>  {
    constructor(props) {
        super(props);
        this.state = {  
          order:null,
          service_fee_rate:0.1,
          tracking_info:"Add here DRIVERS NUMBER & VEHICLE REG\nOr Company/Sacco\nOr G4S Receipt/Code\nOr delivery details."
        }
      } 
      componentDidMount(){
        this.setState({
          order:this.props.order,
      });
    }

      render() {
        let CurrentSnipTextAreaSty = {
          border: '1px solid #314929',
          color: 'green',
          fontSize: '1em',
          height: 'calc(50% - 20px)',
          overflowY: 'auto',
          padding: '5px',
          width: 'calc(100% - 12px)',
          resize: 'none'
      };

      const shipping_address=this.state.order!=null?this.state.order.status_codes.find((status) => {
        return status.order_status_code === ORDER_STATUS_NOT_PAID;
      }):null;

      return (
        this.state.order!=null?
        <Card>
        <Row>
          <Col>
          <div align="center" >
          <Card.Img style={{width:'20rem'}}   variant="left"  src={""} />
          <Card.Text>
          <div align="left" >
                <h4>Order #{this.state.order.id} has been Paid!</h4>
                  The following items are awaiting shipping.

                     {/*Table code */}
                <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <StyledTableCell style={{width:"70%"}}  align="right">Item</StyledTableCell>
                      <StyledTableCell  align="right">@</StyledTableCell>
                      <StyledTableCell  align="right">Cost</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {this.state.order.order_data_json.map((item, index) => { return (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <TableCell padding="none" align="right">{item.name}</TableCell>
                      <TableCell padding="none" align="right">{item.quantity}</TableCell>
                      <TableCell padding="none" align="right">{item.itemTotal}</TableCell>
                    </TableRow>);})}
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <TableCell padding="none" align="right"></TableCell>
                      <TableCell padding="none" align="right">Total</TableCell>
                      <TableCell padding="none" align="right">{this.state.order.total_price}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                </TableContainer>
                   {/*Table code end*/}
                   <h4 style={{color:"red"}}>Shipping to this address:</h4>
                  <textarea value={shipping_address.order_status_json.shipto} style={CurrentSnipTextAreaSty} disabled={true} cols="48" maxCols={48} maxRows={5} rows="5" />
                  
              
                  <br></br>You are supposed to ship this order before it expires.
                 <div style={{color:"red"}}> After shipping add the tracking info  e.g vehicle plates and drivers number here Or you can add receipt details then click confirm! </div>
                  <h6>NB:Incase you fail to respond to the order within the given time a refund will be initiated to the buyer and you may be penalized..</h6>
                  </div>
                </Card.Text>
          </div>
          </Col>
          <Col>
            <Card.Body>
            <Card.Title as="h4" style={{color:"green"}}>Tracking Information</Card.Title>
              </Card.Body>
              <ListGroup className="list-group-flush">
              <ListGroup.Item>
                      <h4 style={{color:"red"}}>Your money on hold:</h4>
                      <h4>{this.state.order.total_price-(this.state.order.total_price*this.state.service_fee_rate)} /-</h4>
                      <h4 style={{color:"red"}}>Customer paid us:</h4>
                      <h4>{this.state.order.total_price}/-</h4>
                      <h4 style={{color:"red"}}>Our service Fee:</h4>
                      <h4>Market Charges: {this.state.order.total_price*this.state.service_fee_rate}/- Or {this.state.service_fee_rate*100}%</h4>
              </ListGroup.Item>
              <ListGroup.Item>
                  <h4 style={{color:"red"}}>Time Untill Expiry</h4>
                  <h5><Countdown
                      date={(shipping_address.date_created*1000)+((3*24*60*60)*1000)}
                      intervalDelay={0}
                      precision={3}
                      renderer={props =>props.completed?<div>Order was cancelled</div>: <div>{props.days>0?props.days+" days ":""}{props.hours>0?props.hours+" hours ":""}{props.minutes>0?props.minutes+" minutes  and ":""}{props.seconds>0?props.seconds+" seconds":""}</div>}
                    /></h5>
              <h5 style={{color:"red"}}>Add Parcel Tracking:</h5>
                  <textarea value={this.state.tracking_info}    onChange={(newValue) => {
                this.setState({
                  ...this.state,
                  tracking_info:newValue.target.value
              });
              }} style={CurrentSnipTextAreaSty} disabled={false} cols="40" maxCols={28} maxRows={5} rows="5" />
              Click here to confirm you have shipped.
              <Button
              onClick={() => { update_order_status(this.state.order,this.state.tracking_info) }}
              >Confirm Shipped</Button>
                </ListGroup.Item>
            </ListGroup>
              <Card.Body>
              </Card.Body>
          </Col>
        </Row>
      </Card>:<div></div>);
      }
    }
export default ClientPaid;
              
