import axios from 'axios';
import axiosRetry from 'axios-retry';
//https://stackoverflow.com/questions/43051291/attach-authorization-header-for-all-axios-requests
const fetchClient = () => {
  const defaultOptions = {
    baseURL: "https://yotakumarket.com/yotakuapi/",
    headers: {
        'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);
// Custom retry delay
  axiosRetry(instance, { retryDelay: axiosRetry.exponentialDelay });
  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    if(token!=null)
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  return instance;
};

export default fetchClient();