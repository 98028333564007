import React from 'react';
import Button from '@mui/material/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { purple,green,red,yellow,orange} from '@mui/material/colors';
import {withRouter} from '../../withRouter';
import Alert from '@mui/material/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {GrStatusGood,GrStatusWarning} from "react-icons/gr";
import {FaAddressBook } from "react-icons/fa";
import { MdMyLocation} from "react-icons/md";
import md5 from 'crypto-js/md5';
import {BsPersonBoundingBox } from "react-icons/bs";
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    width:'100px',
    '&:hover': {
      backgroundColor: red[700],
    },
  }));
const BuyButton  = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: green[500],
    width:'150px',
    '&:hover': {
      backgroundColor: red[700],
    },
  }));
class TraderTile extends  React.Component<any, any>  {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
      }
      handleClick(location) {
        this.props.navigate( location); 
      }
    render() {
        return (
            <div style={{ width: this.props.width===null?'100%':this.props.width,height:this.props.height===null?'30rem':this.props.height }}>
                <Card  expand={"true"} style={{color: '#fff', background: 'url('+this.props.trader.bg_url+')'}}>
        <Row>
              <Col>
              <div align="left" >
              <Link  to={'/product_catalog?trader_id='+ this.props.trader.id} >
                <Card.Title style={{ color:"black" }}><h2>{this.props.trader.name}</h2></Card.Title>
                <Card.Img style={{width:'14rem'}} variant="top" src={"https://www.gravatar.com/avatar/"+md5(this.props.trader.email)+"?s=200"} />
              </Link>  
              </div>
              </Col>
              <Col>
                <Card.Body>
                <ListGroup style={{textAlign:"left"}} className="list-group-flush">
                    <ListGroup.Item><b>Name:</b> <Link to={'/product_catalog?trader_id='+ this.props.trader.id }>{this.props.trader.first_name+" "+this.props.trader.last_name}</Link></ListGroup.Item>
                    <ListGroup.Item><b>Date Registered:</b>{new Date(this.props.trader.date_created*1000).toLocaleString('en-GB', { timeZone: 'Africa/Nairobi'  })}</ListGroup.Item>
                    <ListGroup.Item><GrStatusGood/> {this.props.trader.good_trades} good trades</ListGroup.Item>
                    <ListGroup.Item><GrStatusWarning/> {this.props.trader.bad_trades} bad trades</ListGroup.Item>
                    <ListGroup.Item><BsPersonBoundingBox/> {this.props.trader.id_verification_status?<font color="green">Identification Verified</font>:<font style={{ textDecorationLine: 'line-through' }} color="red">Identification Not Verified</font>}</ListGroup.Item>
                    <ListGroup.Item><MdMyLocation/> {this.props.trader.location_verification_status?<font color="green">Location Verified</font>:<font style={{ textDecorationLine: 'line-through' }} color="red">Location Not Verified</font>}</ListGroup.Item>
                    <ListGroup.Item><FaAddressBook/> {this.props.trader.address_verification_status?<font color="green">Address Verified</font>:<font style={{ textDecorationLine: 'line-through' }} color="red">Address Not Verified</font>}</ListGroup.Item>
                    <ListGroup.Item>
                      <Typography component="legend">Rating</Typography>
                      <Rating name="read-only" max={10} value={2} readOnly />
                    </ListGroup.Item> 
                    <ListGroup.Item hidden={this.props.show_buttons===false}>
                     <div align="left">
                        <ColorButton onClick={() => { this.handleClick('/product_catalog?trader_id='+ this.props.trader.id ) }}  size="small" variant="contained" >My Items</ColorButton>
                        &nbsp;&nbsp;&nbsp;
                      {/*This id should be fixed here*/}
                            <ColorButton  onClick={() => { this.handleClick('/trader_info?trader_id='+ this.props.trader.id  ) }}size="small" variant="contained" >More Info</ColorButton>
                            </div>
                         </ListGroup.Item>
                   {/*
                    <ListGroup.Item>
                      <div align="center" >
                        <BuyButton  onClick={() => { this.handleClick('/buy?product_id='+ this.props.id) }} size="small" variant="contained" >Buy</BuyButton>
                        </div>
                        </ListGroup.Item> */}
                   </ListGroup>
                  </Card.Body>
              </Col>
            </Row>
           
            </Card>
            </div>
        )
    }
}
export default withRouter(TraderTile) ;