import React from 'react';
import TraderCatalog from './catalog/trader/TraderCatalog';
import Navigation from './Navigation';
import store from '../store/store';
import { get_traders_range,clear_traders_catalog} from '../actions/actions';
import { styled } from '@mui/material/styles';
import InfiniteScroll from "react-infinite-scroll-component";
import Paper from '@mui/material/Paper';
import { Stack } from '@mui/material';
import { FidgetSpinner,FallingLines } from 'react-loader-spinner';
import { MDBFooter } from 'mdb-react-ui-kit';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

class TraderViewCatalog extends  React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {  
          traders_list: [],total_length:4,
          load_state:{
            errored:false,
            loading_state_code:0,
            error:"Nothing to report!"
          }
        }
      }
      _isMounted=false;

      componentDidMount() {
        if(this._isMounted===false){
        clear_traders_catalog();
      }
        this._isMounted=true;
        get_traders_range(0,2);
        store.subscribe(() => {
          // When state will be updated(in our case, when items will be fetched), 
          // we will update local component state and force component to rerender 
          // with new data.
          if (this._isMounted) 
          {

            this.setState({
              ...this.state,
              total_length:store.getState().traders_catalog.total_count,
              load_state:store.getState().product_catalog_load_state,
              traders_list: this.state.traders_list.concat(store.getState().traders_catalog.traders.filter(x => !this.state.traders_list.some(y => y.id== x.id))),
    
          })
        }
        }); 
      }
      render() {
        let traders=this.state.traders_list;
        return  (
          this.state.load_state.loading_state_code<2?
          <div>
        <Navigation hidden_search={0}/>
          <div  style={{height: '60vh',display: 'flex',  justifyContent:'center', alignItems:'center'}}>
          <FidgetSpinner
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
            ballColors={['#ff0000', '#00ff00', '#0000ff']}
            backgroundColor="#F4442E"
          /><h3>{this.state.load_state.errored?this.state.load_state.error:"Looking for traders..."}</h3></div></div>:
        <div>
           <Navigation hidden_search={0}/>
        <div  style={{textAlign:"left"}}><h2>Vendors</h2></div>
        <Stack>
        <Item>
         <h3>VARIOUS VENDORS</h3>
        </Item>
        <Item>
           <InfiniteScroll
            scrollableTarget="scrollableDiv"
            dataLength={traders.length}
            next={() => { 
              get_traders_range(traders.length,2);
             }}
            endMessage={
              <p style={{ textAlign: 'center', width:'100%'}}>
                <b>Yay! You have seen it all</b>
              </p>}
          loader={
            <FallingLines
            color="#4fa94d"
            width="100"
            visible={true}
            ariaLabel='falling-lines-loading'
          />
          }
            hasMore={traders.length<this.state.total_length}
          >
            <TraderCatalog  id="scrollableDiv" traders={traders}></TraderCatalog>
  
          </InfiniteScroll>
        
        </Item>
        </Stack>
        <MDBFooter bgColor='dark' className='text-center text-white text-lg-left'>
        <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          &copy; {new Date().getFullYear()} Copyright:{' '}
          <a className='text-white' href='https://yotakumarket.com/'>
            yotakumarket.com
          </a>
        </div>
      </MDBFooter>
        </div>);
      }
}
export default TraderViewCatalog;
