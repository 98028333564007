import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { purple,green,red} from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import NativeSelect from '@mui/material/NativeSelect';
import dayjs from 'dayjs';
import Tooltip from '@mui/material/Tooltip';
import Navigation from './Navigation';
import {withRouter} from './withRouter';
import { MDBFooter } from 'mdb-react-ui-kit';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 16,
    border: 1,
    padding:'1px 0px'
  },
  [`&.${tableCellClasses.body}`]: {
    border: 1,
  },
}));
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  width:'200px',
  '&:hover': {
    backgroundColor: red[700],
  },
}));

const BuyButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: green[700],
  width:'200px',
  height:'22px',
  '&:hover': {
    backgroundColor:red[700], 
  },
}));
const ColorButtonGreen = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: green[500],
  width:'200px',
  '&:hover': {
    backgroundColor: red[700],
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    fontSize: 12,
    border: 1,
    padding:'1px 0px'
  },
}));

const DogecoinInput  =styled(TextField)(({ theme }) => ({
'.MuiInputBase-root': {
  color: "green"
  },
}));

const page_height=500;

class MyAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date_value:dayjs('1998-08-18T21:11:54'),
      lat:0,
      lng:0
    };

  }
    
  componentDidMount() {
    document.title = "Edit account details";

    navigator.geolocation.getCurrentPosition(position => {
      this.setState({
        ...this.state,
          lat: position.coords.latitude, 
          lng: position.coords.longitude
      })
  });
  }
  render(){
      return (
    <div>
      <Navigation hide_mq={1} hidden_search={1} />
     <div  style={{textAlign:"left"}}><h2>Edit Account Info</h2></div>
    <div  style={{textAlign:"center"}}>
  <TableContainer component={Paper}>
    <Table size="small" aria-label="simple table">
    <TableHead>
      <TableRow style={{fontWeight:"bold"}}>
      <TableCell width={200} height={200} align="center" >
        <img alt="PFP" src={'https://www.gravatar.com/avatar/4cf219ac1c45d5b609ae3c4c6d2ff95c?s=200' } />
        <ColorButtonGreen size="small" variant="contained" >Upload Photo</ColorButtonGreen>
      </TableCell>
       <TableCell  align="right">
        {/*START OF RIGHT TABLE*/}
        <Table height={page_height}  size="small" aria-label="simple table">
              <TableHead>
                <StyledTableRow style={{fontWeight:"bold"}}>
                <StyledTableCell  align="right" >Trader Information</StyledTableCell>
                <StyledTableCell align="right">Details</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                    <StyledTableCell style={{fontWeight:"bold"}} align="right">First Name</StyledTableCell>
                    <StyledTableCell align="right"><TextField id="standard-basic" value={"Adam"} label="First Name" variant="standard" /></StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell style={{fontWeight:"bold"}} align="right">Middle Name</StyledTableCell>
                    <StyledTableCell align="right"><TextField id="standard-basic" value={"Cain"} label="Middle Name" variant="standard" /></StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell style={{fontWeight:"bold"}} align="right">Last Name</StyledTableCell>
                    <StyledTableCell align="right"><TextField id="standard-basic" value={"Eve"} label="Last name" variant="standard" /></StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell style={{fontWeight:"bold"}} align="right">Sex</StyledTableCell>
                    <StyledTableCell align="right">
                    <NativeSelect
                    inputProps={{
                      name: 'Sex',
                          id: 'uncontrolled-native',
                        }}
                      defaultValue={1}
                    >
                      <option value={1}>Male</option>
                      <option value={2}>Female</option>
                      <option value={3}>Preffer S/L</option>
                    </NativeSelect>
                    </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell style={{fontWeight:"bold"}} align="right">Date Of Birth</StyledTableCell>
                    <StyledTableCell align="right">
                    <LocalizationProvider align="right" dateAdapter={AdapterDayjs}>
                      <DatePicker align="right"
                        label="Date Of Birth"
                        value={this.state.date_value}

                        onChange={(newValue) => {
                          this.setState({
                            ...this.state,
                            date_value: newValue
                        });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell style={{fontWeight:"bold"}} align="right">Email</StyledTableCell>
                    <StyledTableCell align="right"><TextField id="standard-basic" defaultValue={"me@gmail.com"} label="Email" variant="standard" /></StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <StyledTableCell style={{fontWeight:"bold"}} align="right">Phone Number</StyledTableCell>
                    <StyledTableCell align="right"><TextField id="standard-basic" defaultValue={"+254"} label="Phone number" variant="standard" /></StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
              <StyledTableCell style={{fontWeight:"bold"}} align="right">Account Status</StyledTableCell>
                  <StyledTableCell align="right">Limited</StyledTableCell>
              </StyledTableRow>
                </TableBody>
            </Table>
         {/*END OF INNER TABLE*/}
        </TableCell>
        <TableCell>
              {/*START OF RIGHT TABLE*/}
        <Table height={page_height} size="small" aria-label="simple table">
        <TableHead>
          <StyledTableRow style={{fontWeight:"bold"}}>
          <StyledTableCell  align="right" ></StyledTableCell>
          <StyledTableCell align="right">Details</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
        <StyledTableRow>
                            <StyledTableCell style={{fontWeight:"bold"}} align="right">Country</StyledTableCell>
                            <StyledTableCell align="right"> 
                            <Tooltip title="Will be updated when you use mobile phone">
                            <TextField id="standard-basic" defaultValue={"Kenya"} label="Country" variant="standard" />
                            </Tooltip>
                            </StyledTableCell>
                        </StyledTableRow>
                         <StyledTableRow>
                            <StyledTableCell style={{fontWeight:"bold"}} align="right">Town/City</StyledTableCell>
                            <StyledTableCell align="right"> 
                            <Tooltip title="Will be updated when you use mobile phone">
                            <TextField id="standard-basic" defaultValue={"Eldoret"} label="Town" variant="standard" />
                            </Tooltip>
                            </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell style={{fontWeight:"bold"}} align="right">P.O Box</StyledTableCell>
                            <StyledTableCell align="right"> 
                            <Tooltip title="Put your phone number if you don't have a box starting with 254 or your respective Country code">
                            <TextField id="standard-basic" defaultValue={"11423"} label="P.O Box" variant="standard" />
                            </Tooltip>
                            </StyledTableCell>
                        </StyledTableRow>
          <StyledTableRow>
              <StyledTableCell style={{fontWeight:"bold"}} align="right">Lat</StyledTableCell>
              <StyledTableCell align="right"> 
              <Tooltip title="Will be updated when you use mobile phone">
              <TextField id="standard-basic"  value={this.state.lat} label="Location Lat" variant="standard" />
             </Tooltip>
              </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
              <StyledTableCell style={{fontWeight:"bold"}} align="right">Long</StyledTableCell>
              <StyledTableCell align="right"> 
              <Tooltip title="Will be updated when you use mobile phone">
              <TextField id="standard-basic"  value={this.state.lng} label="Location Lng" variant="standard" />
              </Tooltip>
              </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
              <StyledTableCell style={{fontWeight:"bold"}} align="right">My Dogecoin<br></br>Deposit Address</StyledTableCell>
              <StyledTableCell align="right">
              <Tooltip title="Go to binance -> deposit crypto -> Dogecoin ->Choose network Dogecoin -> Copy your deposit address here.Or you can use any address from main dogecoin chain">
                <DogecoinInput id="standard-basic" defaultValue={"nnY36T2PtRywcp7amPMEVAYgiSpVMC7kow"} label="Main Dogecoin Chain" variant="standard" />
             </Tooltip>
                </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
              <StyledTableCell style={{fontWeight:"bold"}} align="right">Date Registered</StyledTableCell>
              <StyledTableCell align="right">11/1/1980</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
          <StyledTableCell align="right"></StyledTableCell>
          <StyledTableCell style={{fontWeight:"bold"}} align="right">
            <ColorButton size="small" variant="contained" >Identity verification</ColorButton>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
          <StyledTableCell align="right"><BuyButton   size="small" variant="contained" >Save Information</BuyButton></StyledTableCell>
          <StyledTableCell style={{fontWeight:"bold"}} align="right">
          <Tooltip title="Use your phone to update location details">
          <span>
            <ColorButton  disabled size="small" variant="contained" >Update Address</ColorButton>
            </span>
            </Tooltip>
            </StyledTableCell>
          </StyledTableRow>
        
        
        </TableBody>
        </Table>
        {/*END OF INNER TABLE*/}
          </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      </TableBody>
    </Table>
  </TableContainer>
  </div>
  <MDBFooter bgColor='dark' className='text-center text-white text-lg-left'>
        <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          &copy; {new Date().getFullYear()} Copyright:{' '}
          <a className='text-white' href='https://yotakumarket.com/'>
            yotakumarket.com
          </a>
        </div>
      </MDBFooter>
        </div>    
      );
    }
  }

  export default withRouter(MyAccount);
