import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePaginationUnstyled, {
  tablePaginationUnstyledClasses as classes,
} from '@mui/base/TablePaginationUnstyled';
import Paper from '@mui/material/Paper';
import {withRouter} from './withRouter';
import { compose } from 'redux';
import Navigation from './Navigation';
import Button from '@mui/material/Button';
import { purple,green,red,orange} from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import store from '../store/store';
import {NotificationManager} from 'react-notifications';
import { keyframes } from '@mui/system';
import { FidgetSpinner } from 'react-loader-spinner';
import { BsFillArrowDownLeftCircleFill ,BsFillArrowUpRightCircleFill} from "react-icons/bs";
import Stack from '@mui/material/Stack';
import { get_my_transactions} from '../actions/actions';
import { MDBFooter } from 'mdb-react-ui-kit';
const INPROGRESS=0;
const ACCEPTED=1;
const REFUNDED=2;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: 16,
          border:1
    },
  }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:hover": {
        backgroundColor: "LightBlue !important",
        color:"black"
      }
    })); 

const ColorButtonGreen = styled(Button)(({ theme }) => ({
    fontSize:'10px',
    height:'25px',
    color: "white",
    backgroundColor: "orangered",
    '&:hover': {
      backgroundColor: red[700],
    },
    '&:disabled': {
      color: "white",
    },
  }));
  const NTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
      padding:'0px 4px',
      borderBottom: "1px solid black",
      borderRight: "1px solid black",
    },
  }));
  
 
const CustomTablePagination = styled(TablePaginationUnstyled)`
& .${classes.toolbar} {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
}

& .${classes.selectLabel} {
  margin: 0;
}

& .${classes.displayedRows} {
  margin: 0;

  @media (min-width: 768px) {
    margin-left: auto;
  }
}

& .${classes.spacer} {
  display: none;
}

& .${classes.actions} {
  display: flex;
  gap: 0.25rem;
}
`;

class Transactions  extends React.Component<any, any>   {
    constructor(props) {
      super(props);
  
      this.state = {
        transactions:null,


        page_outgoing:0,
        rowsPerPage_outgoing:5,
        page_incoming:0,
        rowsPerPage_incoming:5
      };
  
    }
    _isMounted=false;
    
  handleChangePage_outgoing = (event: unknown, newPage: number) => {
    this.setState({
      ...this.state,
    page_outgoing:newPage
  })
  };

  handleChangeRowsPerPage_outgoing = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
    page_outgoing:0,
    rowsPerPage_outgoing:parseInt(event.target.value, 10)
  })
  };
  handleChangePage_incoming = (event: unknown, newPage: number) => {
    this.setState({
      ...this.state,
    page_incoming:newPage
  })
  };

  handleChangeRowsPerPage_incoming = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
    page_incoming:0,
    rowsPerPage_incoming:parseInt(event.target.value, 10)
  })
  };

    componentDidMount() {
      document.title = "Recent transactions";
      this._isMounted=true;
      get_my_transactions();
    store.subscribe(() => {
      // When state will be updated(in our case, when items will be fetched), 
      // we will update local component state and force component to rerender 
      // with new data.
      if (this._isMounted) 
      {
        this.setState({
          ...this.state,
          transactions:  store.getState().transactions,

      })
    }
    });
      } 

 render() {
  const emptyRows_incoming =this.state.page_incoming > 0 ? Math.max(0, (1 + this.state.page_incoming) *  this.state.rowsPerPage_incoming - this.state.transactions.transactions_outgoing.length) : 0;
  const emptyRows_outgoing =this.state.page_outgoing > 0 ? Math.max(0, (1 + this.state.page_outgoing) *  this.state.rowsPerPage_outgoing - this.state.transactions.transactions_incoming.length) : 0;
  return (
    this.state.transactions===null? <div><Navigation hidden_search={0}/>
        <div  style={{textAlign:"left"}}> <h3>Your incoming & Outgoing transactions</h3> </div>
        <div  style={{height: '60vh',display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <FidgetSpinner
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
          ballColors={['#ff0000', '#00ff00', '#0000ff']}
          backgroundColor="#F4442E"
        /><h3>Loading transactions...</h3></div></div>:
    <div><Navigation hidden_search={0}/>
 <Stack spacing={2} >
    <Item>
    <div  style={{textAlign:"left"}}> <h3>Your outgoing transactions</h3> </div>
    <div style={{width:"100%"}}>
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
            <StyledTableCell style={{width:"30px"}}  align="right">#ID</StyledTableCell>
            <StyledTableCell style={{width:"90px"}} align="right">Date</StyledTableCell>
            <StyledTableCell style={{width:"20px"}} align="right">Fee</StyledTableCell>
            <StyledTableCell style={{width:"150px"}} align="right">Total</StyledTableCell>
            <StyledTableCell style={{width:"150px"}} align="right">Currency</StyledTableCell>
            <StyledTableCell style={{width:"150px"}} align="right">STATUS</StyledTableCell>
            <StyledTableCell style={{width:"20px"}} align="right"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {this.state.transactions===null?<div  style={{textAlign:"center"}}>Nothing to show</div>: (this.state.rowsPerPage_outgoing > 0
            ? this.state.transactions.transactions_outgoing.slice(this.state.page_outgoing * this.state.rowsPerPage_outgoing, this.state.page_outgoing * this.state.rowsPerPage_outgoing + this.state.rowsPerPage_outgoing)
            : this.state.transactions.transactions_outgoing
          ).map((row) => (
             <StyledTableRow  key={"data_"+row.id} >
             <NTableCell align="right">{"#"+row.id}</NTableCell>
             <NTableCell align="right">{new Date(row.date_created*1000).toLocaleString('en-GB', { timeZone: 'Africa/Nairobi'  })}</NTableCell>
             <NTableCell align="right">{row.fee}</NTableCell>
             <NTableCell align="right">{row.ammount}</NTableCell>
             <NTableCell align="right">{row.currency}</NTableCell>
             <NTableCell align="right">{row.status===ACCEPTED?"ACCEPTED":row.status===REFUNDED?"REFUNDED":row.status===INPROGRESS?"INPROGRESS":"N.A"}</NTableCell>
             <NTableCell  align="center"><ColorButtonGreen sx={{width:'90%'}}>View</ColorButtonGreen></NTableCell>
           </StyledTableRow >
          ))}
          
        </TableBody>
      </Table>
    </TableContainer>
    <CustomTablePagination
          rowsPerPageOptions={[5, 10, 25]}
          count={this.state.transactions.transactions_outgoing.length}
          rowsPerPage={this.state.rowsPerPage_outgoing}
          page={this.state.page_outgoing}
          onPageChange={this.handleChangePage_outgoing}
          onRowsPerPageChange={this.handleChangeRowsPerPage_outgoing}
        />
    </div>
    </Item>
    <Item>
    <div  style={{textAlign:"left"}}> <h3>Your incoming transactions</h3> </div>
    <div style={{width:"100%"}}>
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
            <StyledTableCell style={{width:"30px"}}  align="right">#ID</StyledTableCell>
            <StyledTableCell style={{width:"90px"}} align="right">Date</StyledTableCell>
            <StyledTableCell style={{width:"20px"}} align="right">Fee</StyledTableCell>
            <StyledTableCell style={{width:"150px"}} align="right">Total</StyledTableCell>
            <StyledTableCell style={{width:"150px"}} align="right">Currency</StyledTableCell>
            <StyledTableCell style={{width:"150px"}} align="right">STATUS</StyledTableCell>
            <StyledTableCell style={{width:"20px"}} align="right"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {this.state.transactions===null?<div  style={{textAlign:"center"}}>Nothing to show</div>: (this.state.rowsPerPage_incoming > 0
            ? this.state.transactions.transactions_incoming.slice(this.state.page_incoming * this.state.rowsPerPage_incoming, this.state.page_incoming * this.state.rowsPerPage_incoming + this.state.rowsPerPage_incoming)
            : this.state.transactions.transactions_incoming
          ).map((row) => (
             <StyledTableRow  key={"data_"+row.id} >
             <NTableCell align="right">{"#"+row.id}</NTableCell>
             <NTableCell align="right">{new Date(row.date_created*1000).toLocaleString('en-GB', { timeZone: 'Africa/Nairobi'  })}</NTableCell>
             <NTableCell align="right">{row.fee}</NTableCell>
             <NTableCell align="right">{row.ammount}</NTableCell>
             <NTableCell align="right">{row.currency}</NTableCell>
             <NTableCell align="right">{row.status===ACCEPTED?"ACCEPTED":row.status===REFUNDED?"REFUNDED":row.status===INPROGRESS?"INPROGRESS":"N.A"}</NTableCell>
             <NTableCell  align="center"><ColorButtonGreen sx={{width:'90%'}}>View</ColorButtonGreen></NTableCell>
           </StyledTableRow >
          ))}
          
        </TableBody>
      </Table>
    </TableContainer>
    <CustomTablePagination
          rowsPerPageOptions={[5, 10, 25]}
          count={this.state.transactions.transactions_incoming.length}
          rowsPerPage={this.state.rowsPerPage_incoming}
          page={this.state.page_incoming}
          onPageChange={this.handleChangePage_incoming}
          onRowsPerPageChange={this.handleChangeRowsPerPage_incoming}
        />
    </div>
    </Item>
    
  </Stack>
  <MDBFooter bgColor='dark' className='text-center text-white text-lg-left'>
        <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          &copy; {new Date().getFullYear()} Copyright:{' '}
          <a className='text-white' href='https://yotakumarket.com/'>
            yotakumarket.com
          </a>
        </div>
      </MDBFooter>
    </div>
  );
}

}

export default compose(
    withRouter
  )(Transactions);