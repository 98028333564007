import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import Countdown from 'react-countdown';
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import { Chat, MessageList, MessageInput,TypingIndicator,MessageRendererProps, useUsers } from "@pubnub/react-chat-components";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import store from '../../store/store';
import md5 from 'crypto-js/md5';
import {  
  ORDER_STATUS_NOT_PAID,
    ORDER_STATUS_PAID,
    ORDER_STATUS_COMPLETED,
    ORDER_STATUS_SHIPPED,
    ORDER_STATUS_FAILED,
    ORDER_STATUS_DISPUTE,
    ORDER_STATUS_CLIENT_PAID,
    ORDER_STATUS_CLIENT_SHIPPED} from '../../constants/order_status';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 16,
        border:1
  },
}));
const theme = "light";


const renderMessage = (props: MessageRendererProps) => {
  return (
    <div>
        <strong>{props.time}:{props.isOwn?"Me":props.user}</strong>  {props.message.message.text}
    </div>
  );
};

class Dispute extends  React.Component<any, any>  {
    constructor(props) {
        super(props);
        this.state = {  
          order:null,
          pubnubConfig:{
            publishKey: "pub-c-fbe891cd-9192-4271-9028-28e788d4e5b0",
            subscribeKey: "sub-c-278a18a7-03ca-475a-90b7-ea808378a695",
            uuid: "1",
          }
        }
        
      } 

      

    




      componentDidMount(){
        this.setState({
          order:this.props.order,
          pubnubConfig:{
            publishKey: "pub-c-fbe891cd-9192-4271-9028-28e788d4e5b0",
            subscribeKey: "sub-c-278a18a7-03ca-475a-90b7-ea808378a695",
            uuid: md5(this.props.user_info.id.toString()).toString(),
          }
      });
    
        
      

    }
      render() {

        let currentChannel = this.state.order===null?"":"Disput_#"+this.state.order.id;
        const   pubnub =  new PubNub(this.state.pubnubConfig);
        
        let CurrentSnipTextAreaSty = {
          border: '1px solid #314929',
          color: 'green',
          fontSize: '1em',
          height: 'calc(50% - 20px)',
          overflowY: 'auto',
          padding: '5px',
          width: 'calc(100% - 12px)',
          resize: 'none'
      };

      const shipping_address=this.state.order!=null?this.state.order.status_codes.find((status) => {
        return status.order_status_code === ORDER_STATUS_NOT_PAID;
      }):null;

      const tracking_info=this.state.order!=null?this.state.order.status_codes.find((status) => {
        return status.order_status_code === ORDER_STATUS_SHIPPED;
      }):null;

 
      return (
        this.state.order!=null?
        <Card>
        <Row>
          <Col>
          <div align="center" >
          <Card.Img style={{width:'20rem'}}   variant="left"  src={""} />
          <Card.Text>
          <div align="left" >
                <h4>Order #{this.state.order.id} has a dispute!</h4>
                  To resolve this dispute please add all the information relating to the dispute.
                  <br></br>We will look into the information provided and advice accordingly.
                  {/*Table code */}
                <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <StyledTableCell style={{width:"70%"}}  align="right">Item</StyledTableCell>
                      <StyledTableCell  align="right">@</StyledTableCell>
                      <StyledTableCell  align="right">Cost</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {this.state.order.order_data_json.map((item, index) => { return (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <TableCell padding="none" align="right">{item.name}</TableCell>
                      <TableCell padding="none" align="right">{item.quantity}</TableCell>
                      <TableCell padding="none" align="right">{item.itemTotal}</TableCell>
                    </TableRow>);})}
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <TableCell padding="none" align="right"></TableCell>
                      <TableCell padding="none" align="right">Total</TableCell>
                      <TableCell padding="none" align="right">{this.state.order.total_price}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                </TableContainer>
                <br></br>The following is the order history.
                  <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <StyledTableCell style={{width:"5%"}}  align="right">#</StyledTableCell>
                      <StyledTableCell  align="right">DATE</StyledTableCell>
                      <StyledTableCell  align="right">STATUS</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {this.state.order.status_codes.map((status, index) => { return (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <TableCell padding="none" align="right">{status.id}</TableCell>
                      <TableCell padding="none" align="right">{new Date(status.date_created*1000).toLocaleString('en-GB', { timeZone: 'Africa/Nairobi'  })}</TableCell>
                      <TableCell padding="none" align="right">{status.order_status_code===ORDER_STATUS_PAID?"PAID":
                                        status.order_status_code===ORDER_STATUS_NOT_PAID?"NOT_PAID":
                                        status.order_status_code===ORDER_STATUS_COMPLETED?"COMPLETED":
                                        status.order_status_code===ORDER_STATUS_SHIPPED?"SHIPPING":
                                        status.order_status_code===ORDER_STATUS_FAILED?"FAILED":
                                        status.order_status_code===ORDER_STATUS_DISPUTE?"DISPUTE":
                                        status.order_status_code===ORDER_STATUS_CLIENT_PAID?"SHIPPING":
                                        status.order_status_code===ORDER_STATUS_CLIENT_SHIPPED?"FINALIZING"
                                        :"N.A"}</TableCell>
                    </TableRow>);})}
                  </TableBody>
                </Table>
                </TableContainer>
                   {/*Table code end*/}
                  <h4>Please Type you message here</h4>
                    <PubNubProvider client={pubnub}>
                      {/* PubNubProvider is a part of the PubNub React SDK and allows you to access PubNub instance
                      in components down the tree. */}
                      <Chat  
                      theme={theme}
                      users={[
                        md5(this.props.user_info.id.toString()).toString(),
                        md5((2).toString()).toString()
                      ]}
                      currentChannel={currentChannel}
                      >
                        {/* Chat is an obligatory state provider. It allows you to configure some common component
                        options, like the current channel and the general theme for the app. */}
                        <MessageList  messageRenderer={renderMessage} fetchMessages={1005} enableReactions={true} >
                          <TypingIndicator />
                        </MessageList>
                        <MessageInput typingIndicator  />
                      </Chat>
                    </PubNubProvider>


                  <h6>NB:Incase this dispute does not solve your problem,we can provide assistance legally as you can still pursue legal resolve.</h6>
                  </div>
                </Card.Text>
          </div>
          </Col>
          <Col>
            <Card.Body>
            <Card.Title as="h4" style={{color:"green"}}>Shipping details</Card.Title>
              </Card.Body>
              <ListGroup className="list-group-flush">
              <ListGroup.Item>
                      <h4 style={{color:"red"}}>Payment on Hold:</h4>
                      <h4>{this.state.order.total_price} /</h4>
             </ListGroup.Item>
             <ListGroup.Item>
             <h4 style={{color:"red"}}>Shipping to:</h4>
                  <textarea value={shipping_address.order_status_json.shipto} style={CurrentSnipTextAreaSty} disabled={true} cols="48"  rows="5" />
                  </ListGroup.Item>
             <ListGroup.Item>
             <h4 style={{color:"red"}}>Tracking info</h4>
             <textarea value={tracking_info.order_status_json.tracking} style={CurrentSnipTextAreaSty} disabled={true} cols="48"  rows="5" />
                 
                  </ListGroup.Item>
            </ListGroup>
              <Card.Body>
              </Card.Body>
          </Col>
        </Row>
      </Card>:<div></div>);
      }
    }   
export default Dispute;