import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {  
  ORDER_STATUS_NOT_PAID,
    ORDER_STATUS_PAID,
    ORDER_STATUS_COMPLETED,
    ORDER_STATUS_SHIPPED,
    ORDER_STATUS_FAILED,
    ORDER_STATUS_DISPUTE,
    ORDER_STATUS_CLIENT_PAID,
    ORDER_STATUS_CLIENT_SHIPPED} from '../../constants/order_status';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 16,
        border:1
  },
}));
class Failed extends  React.Component<any, any>  {
    constructor(props) {
        super(props);
        this.state = {  
        order:null,
        service_fee_rate:0.1
        }
      } 
      componentDidMount(){
        this.setState({
          order:this.props.order,
      });
    }
      render() {
        let CurrentSnipTextAreaSty = {
          border: '1px solid #314929',
          color: 'green',
          fontSize: '1em',
          height: 'calc(50% - 20px)',
          overflowY: 'auto',
          padding: '5px',
          width: 'calc(100% - 12px)',
          resize: 'none'
      };
        
        const failed_status=this.state.order!=null?this.state.order.status_codes.find((status) => {
          return status.order_status_code === ORDER_STATUS_FAILED;
        }):null;
      return (
        this.state.order!=null?
        <Card>
        <Row>
          <Col>
          <div align="center" >
          <Card.Img style={{width:'20rem'}}   variant="left"  src={""} />
          <Card.Text>
          <div align="left" >
                <h4>This order Failed!</h4>
                  <br></br>The following is the order history.
                  <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <StyledTableCell style={{width:"5%"}}  align="right">#</StyledTableCell>
                      <StyledTableCell  align="right">DATE</StyledTableCell>
                      <StyledTableCell  align="right">STATUS</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {this.state.order.status_codes.map((status, index) => { return (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <TableCell padding="none" align="right">{status.id}</TableCell>
                      <TableCell padding="none" align="right">{new Date(status.date_created*1000).toLocaleString('en-GB', { timeZone: 'Africa/Nairobi'  })}</TableCell>
                      <TableCell padding="none" align="right">{status.order_status_code===ORDER_STATUS_PAID?"PAID":
                                        status.order_status_code===ORDER_STATUS_NOT_PAID?"CREATED":
                                        status.order_status_code===ORDER_STATUS_COMPLETED?"COMPLETED":
                                        status.order_status_code===ORDER_STATUS_SHIPPED?"SHIPPING":
                                        status.order_status_code===ORDER_STATUS_FAILED?"FAILED":
                                        status.order_status_code===ORDER_STATUS_DISPUTE?"DISPUTE":
                                        status.order_status_code===ORDER_STATUS_CLIENT_PAID?"SHIPREQUEST":
                                        status.order_status_code===ORDER_STATUS_CLIENT_SHIPPED?"FINALIZING"
                                        :"N.A"}</TableCell>
                    </TableRow>);})}
                  </TableBody>
                </Table>
                </TableContainer>
                <h4 style={{color:"red"}}>Reasons for failure:</h4>
                  <textarea value={failed_status.order_status_json.reason} style={CurrentSnipTextAreaSty} disabled={true} cols="48"  rows="5" />
                  </div>
                </Card.Text>
          </div>
          </Col>
          <Col>
            <Card.Body>
            <Card.Title as="h4" style={{color:"green"}}>Details about your payment</Card.Title>
              </Card.Body>
              <ListGroup className="list-group-flush">
              <ListGroup.Item>
                      <h4 style={{color:"red"}}>Paid to Seller:</h4>
                      <h4>{this.state.order.total_price-(this.state.order.total_price*this.state.service_fee_rate)} /-</h4>
                      <h4 style={{color:"red"}}>Client Paid:</h4>
                      <h4>{this.state.order.total_price}/-</h4>
                      <h4 style={{color:"red"}}>Service Fee:</h4>
                      <h4>{this.state.order.total_price*this.state.service_fee_rate}/- Or {this.state.service_fee_rate*100}%</h4>
              </ListGroup.Item>
              <ListGroup.Item>
                  {/*Table code */}
                <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <StyledTableCell style={{width:"70%"}}  align="right">Item</StyledTableCell>
                      <StyledTableCell  align="right">@</StyledTableCell>
                      <StyledTableCell  align="right">Cost</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {this.state.order.order_data_json.map((item, index) => { return (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <TableCell padding="none" align="right">{item.name}</TableCell>
                      <TableCell padding="none" align="right">{item.quantity}</TableCell>
                      <TableCell padding="none" align="right">{item.itemTotal}</TableCell>
                    </TableRow>);})}
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                      <TableCell padding="none" align="right"></TableCell>
                      <TableCell padding="none" align="right">Total</TableCell>
                      <TableCell padding="none" align="right">{this.state.order.total_price}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                </TableContainer>
                   {/*Table code end*/}
                </ListGroup.Item>
            </ListGroup>
              <Card.Body>
              </Card.Body>
          </Col>
        </Row>
      </Card>:<div></div>);
      }
    }
export default Failed;
              
