
import React from 'react';
import {withCart} from './withCart';
import {withRouter} from './withRouter';
import { compose } from 'redux';
import Button from 'react-bootstrap/Button';
import store from '../store/store';
import { get_products_range,create_new_order } from '../actions/actions';
import { styled } from '@mui/material/styles';
import { purple,green,red,yellow,orange} from '@mui/material/colors';
const ColorButton = styled(Button)(({ theme }) => ({
    width:'200px',
  }));
class Cart extends React.Component<any, any>  {
    constructor(props) {
      super(props);
      this.state = {  products: [] }
      this.handleClick = this.handleClick.bind(this);
    }
    _isMounted=false;
    handleClick(location) {
        this.props.navigate( location); 
      }

    componentDidMount() {

        this._isMounted = true;
        get_products_range(0,2,0,0);
        store.subscribe(() => {
            // When state will be updated(in our case, when items will be fetched), 
            // we will update local component state and force component to rerender 
            // with new data.
            if (this._isMounted) 
            {
              this.setState({
                ...this.state,
                products: this.state.products.concat(store.getState().store_products.filter(x => !this.state.products.some(y => y.id== x.id))),
            });
          }
          });

    } 
    render() {

    return (      
        <section className="container">
        <div className="row jistufy-content-center">
            <div className="col-12">
            <h5>
                {" "}
            You have {this.props.totalUniqueItems} type of Items, Total items {this.props.totalItems} you can choose from {this.state.products.length} different Items
            </h5>
            <table className="table table-light m-0">
                <tbody>
                {this.props.items.map((item, index) => {
                    return (
                    <tr key={index}>
                        <td>
                        <img  onClick={() => { this.handleClick('/product?product_id='+ item.id) }} src={item.images[0]} style={{ height: "6rem" }} />
                        </td>
                        <td>{item.name}<br></br>SN:<font color="red">{item.sn}</font></td>
                        <td>{item.quantity}</td>
                        <td>x</td>
                        <td>{item.price.toFixed(4)}/-</td>
                        <td>=</td>
                        <td>{(item.price*item.quantity).toFixed(4)}/-</td>
                        <td>
                        <Button size="sm"  style={{width:"30px"}} 
                            onClick={() =>
                            this.props.updateItemQuantity(item.id, item.quantity - 1)
                            }
                            className="btn btn-info ms-2" >
                            {" "}
                            -{" "}
                        </Button >
                        <Button style={{width:"30px"}} size="sm" disabled={this.state.products.length>0?item.quantity>=this.state.products.find((el) => el.id === item.id).quantity:true}
                            onClick={() =>
                            this.props.updateItemQuantity(item.id, item.quantity + 1)
                            }
                            className="btn btn-info ms-2">
                            {" "}
                            +{" "}
                        </Button >
                        <Button 
                            onClick={() => this.props.removeItem(item.id)}
                            className="btn btn-danger ms-2">
                            {" "}
                            Remove{" "}
                        </Button >
                        </td>
                    </tr>
                    );
                })}
                </tbody>
            </table>

            <div className="col-auto ms-auto">
                <h2>  <i></i></h2>
            </div>
         <div style={{alignContent:"right"}}>
         <table>
         <thead>   
         <tr>
            <th style={{width:"50%"}}></th>
            <th style={{width:"20%"}}><h4>Total price :</h4></th>
            <th><h5>{this.props.cartTotal.toFixed(4)}/-</h5></th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td></td>
            <td></td>
            <td> <ColorButton  variant="secondary" onClick={() => this.props.emptyCart()} className="btn btn-danger ms-2">
                Clear Cart
          </ColorButton > 
          </td>
            <td>
          <ColorButton    onClick={() => create_new_order({"cart":this.props.items},this.props.emptyCart) } variant="primary" >
           Checkout
          </ColorButton >
          </td>
        </tr>
        </tbody>
         </table>
          </div>
            </div>
        </div>
        </section>);
    }
}
export default compose(
    withCart,
    withRouter)(Cart);