
import React from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {withRouter} from '../../withRouter';
import {withCart} from '../../withCart';
import axios from "axios";
import {NotificationManager} from 'react-notifications';
import { compose } from 'redux';
import Button from '@mui/material/Button';
import {FidgetSpinner } from 'react-loader-spinner';
import { get_invoice } from '../../../actions/actions';
import store from '../../../store/store';
import Countdown from 'react-countdown';
class CryptoInvoice extends  React.Component<any, any>  {
  constructor(props) {
      super(props);
      this.state = {  
       invoice:null,
       is_valid:true
      }
    } 
    _isMounted=false;
    componentWillUnmount(){
      
    }
    componentDidMount() {
      this._isMounted=true;
    
      store.subscribe(() => {
        if (this._isMounted) 
        {
          this.setState({
            invoice:store.getState().invoice
        })}
      });
    } 
    render() {
      console.log("Invoice", this.state.invoice);
    return (
            this.state.invoice===null?<div  style={{height: '60vh',display: 'flex',  justifyContent:'center', alignItems:'center'}}>
              <FidgetSpinner
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
            ballColors={['#ff0000', '#00ff00', '#0000ff']}
            backgroundColor="#F4442E"
          /><h3>Loading Invoice #{this.props.invoice_id}...</h3>
            </div>:
            <Card>
            <Row>
              <Col>
              <div align="center" >
              <Card.Img style={{width:'20rem'}}   variant="left"  src={this.state.invoice.crypto_qr} />
              <Button  size="small" variant="contained" >How to Pay video</Button>
              <Card.Text>
              <div align="left" >
                    <h4>How to Pay</h4>
                      Open binance and click on the qrcode scan button and scan this qr code.
                      <br></br>You will be redirected to view your order once payment is made.
                      <h5>NB:Your order will not be shipped untill payment is made..</h5>
                      </div>
                    </Card.Text>
              </div>
              </Col>
              <Col>
                <Card.Body>
                <Card.Title as="h4" style={{color:"green"}}>Crypto Payment</Card.Title>
                  </Card.Body>
                  <ListGroup className="list-group-flush">
                  <ListGroup.Item>
                      <h4 style={{color:"red"}}>Invoice Name:</h4>
                      <h4>{this.state.invoice.invoce_name}</h4>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <h4 style={{color:"red"}}>Ammount Payable</h4>
                    <h4>{this.state.invoice.ammount_payable} {this.state.invoice.currency}</h4>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <h4 style={{color:"red"}}>Invoice Expires:</h4>
                      <h4><Countdown
                      date={this.state.invoice.invoice_expire_time}
                      intervalDelay={0}
                      precision={3}
                      onComplete={() => { this.props.set_pay_page(0)  }} 
                      renderer={props =>props.completed?<div>Invoice was cancelled</div>: <div>{props.days>0?props.days+" days ":""}{props.hours>0?props.hours+" hours ":""}{props.minutes>0?props.minutes+" minutes  and ":""}{props.seconds>0?props.seconds+" seconds":""}</div>}
                    /></h4>
                    </ListGroup.Item>

                  </ListGroup>
                  <Card.Body>
                  </Card.Body>
              </Col>
            </Row>
          </Card>
    );
}
}

export default compose(
  withCart,
  withRouter
)(CryptoInvoice);