import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePaginationUnstyled, {
  tablePaginationUnstyledClasses as classes,
} from '@mui/base/TablePaginationUnstyled';
import Paper from '@mui/material/Paper';
import {withRouter} from './withRouter';
import { compose } from 'redux';
import Navigation from './Navigation';
import Button from '@mui/material/Button';
import { purple,green,red,orange} from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import axios from "axios";
import {NotificationManager} from 'react-notifications';
import { keyframes } from '@mui/system';
import { FidgetSpinner } from 'react-loader-spinner';
import { get_invoice,set_modal_page,get_my_orders,set_current_order_id } from '../actions/actions';
import store from '../store/store';
import { MDBFooter } from 'mdb-react-ui-kit';
import {  
  ORDER_STATUS_NOT_PAID,
  ORDER_STATUS_PAID,
  ORDER_STATUS_SHIPPED,
  ORDER_STATUS_DISPUTE,
  ORDER_STATUS_FAILED,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_CLIENT_NOT_PAID,
  ORDER_STATUS_CLIENT_PAID,
  ORDER_STATUS_CLIENT_SHIPPED} from '../constants/order_status';

  
import { BsFillArrowDownLeftCircleFill ,BsFillArrowUpRightCircleFill} from "react-icons/bs";
const blink = keyframes`
  from { opacity: 0.7; }
  to { opacity: 1; }
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: 16,
          border:1
    },
  }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
      "&:hover": {
        backgroundColor: "LightBlue !important",
        color:"black"
      }
    })); 

const ColorButtonGreen = styled(Button)(({ theme }) => ({
    fontSize:'10px',
    height:'25px',
    color: "white",
    backgroundColor: "orangered",
    '&:hover': {
      backgroundColor: red[700],
    },
    '&:disabled': {
      color: "white",
    },
  }));
  const NTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
      padding:'0px 4px',
      borderBottom: "1px solid black",
      borderRight: "1px solid black",
    },
  }));

 
const CustomTablePagination = styled(TablePaginationUnstyled)`
& .${classes.toolbar} {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
}

& .${classes.selectLabel} {
  margin: 0;
}

& .${classes.displayedRows} {
  margin: 0;

  @media (min-width: 768px) {
    margin-left: auto;
  }
}

& .${classes.spacer} {
  display: none;
}

& .${classes.actions} {
  display: flex;
  gap: 0.25rem;
}
`;
  
class Orders  extends React.Component<any, any>   {
    constructor(props) {
      super(props);
  
      this.state = {
        orders:null,
        user_info:null,
        page:0,
        rowsPerPage:5
      };

    }
    _isMounted=false;
  handleChangePage = (event: unknown, newPage: number) => {
    this.setState({
      ...this.state,
    page:newPage
  })
  };

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
    page:0,
    rowsPerPage:parseInt(event.target.value, 10)
  })
  };
  interval;
  componentWillUnmount() {
    clearInterval(this.interval);
    console.log("Closed orders...");
  }

    componentDidMount() {
      document.title = "Recent Orders";
      get_my_orders();
      this._isMounted=true;

      this.interval = setInterval(function() {
        const user_info=store.getState().user_info;
        if(user_info!=null)
        get_my_orders();
        console.log("Refreshing orders");
      }.bind(this), 60000);
       
  

      store.subscribe(() => {
        // When state will be updated(in our case, when items will be fetched), 
        // we will update local component state and force component to rerender 
        // with new data.
        if (this._isMounted) 
        {
          this.setState({
            ...this.state,
            orders:  store.getState().orders,
            user_info:  store.getState().user_info

        })
      }
      });
      } 

 render() {

  const emptyRows =this.state.page > 0 ? Math.max(0, (1 + this.state.page) *  this.state.rowsPerPage - this.state.orders.length) : 0;
  return (
    this.state.orders===null? <div><Navigation hidden_search={0}/>
        <div  style={{textAlign:"left"}}> <h3>Your incoming<BsFillArrowDownLeftCircleFill color={"blue"}/> | Outgoing<BsFillArrowUpRightCircleFill color={"indigo"}/> orders</h3> </div>
        <div  style={{height: '60vh',display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <FidgetSpinner
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
          ballColors={['#ff0000', '#00ff00', '#0000ff']}
          backgroundColor="#F4442E"
        /><h3>Loading Orders...</h3></div></div>:
    <div><Navigation hidden_search={0}/>
   <div  style={{textAlign:"left"}}> <h3>Your incoming<BsFillArrowDownLeftCircleFill color={"blue"}/> | Outgoing<BsFillArrowUpRightCircleFill color={"indigo"}/> orders</h3> </div>
    <div style={{width:"100%"}}>
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
            <StyledTableCell padding="none" style={{width:"30px"}}  align="right">#ID</StyledTableCell>
            <StyledTableCell padding="none"  style={{width:"20px"}} align="right"></StyledTableCell>
            <StyledTableCell padding="none" style={{width:"90px"}} align="right">Trader</StyledTableCell>
            <StyledTableCell padding="none" style={{width:"150px"}} align="right">Date Created</StyledTableCell>
            <StyledTableCell padding="none" style={{width:"90px"}} align="right">Ammount</StyledTableCell>
            <StyledTableCell padding="none" style={{width:"50px"}} align="right">STATUS</StyledTableCell>
            <StyledTableCell padding="none" align="right">Shipping status</StyledTableCell>
            <StyledTableCell padding="none" style={{width:"90px"}} align="right"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {this.state.orders===null?<div  style={{textAlign:"center"}}>Nothing to show</div>: this.state.orders.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => (
             <StyledTableRow  key={"data_"+row.id} sx={{ border: "1",
                backgroundColor: (row.status_code===ORDER_STATUS_NOT_PAID||row.status_code===ORDER_STATUS_PAID||ORDER_STATUS_NOT_PAID||row.status_code===ORDER_STATUS_CLIENT_PAID)?"LawnGreen":(row.status_code===ORDER_STATUS_DISPUTE)?"red":(row.status_code===ORDER_STATUS_SHIPPED)?"cyan":"" ,
                animation: (row.status_code===ORDER_STATUS_NOT_PAID||row.status_code===ORDER_STATUS_PAID||row.status_code===ORDER_STATUS_SHIPPED)?`${blink} 3s linear infinite`:"",
             }}>
             <NTableCell padding="none" align="right">{"#"+row.id}</NTableCell>
             <NTableCell padding="none" align="right">{row.trader_id===this.state.user_info.id?<BsFillArrowUpRightCircleFill size={"20px"} color={"indigo"}/>:< BsFillArrowDownLeftCircleFill size={"20px"} color={"blue"}/>}</NTableCell>
             <NTableCell padding="none" align="right">{row.trader_id===this.state.user_info.id?row.customer_name:row.trader_name}</NTableCell>
             <NTableCell padding="none" align="right">{new Date(row.date_created*1000).toLocaleString('en-GB', { timeZone: 'Africa/Nairobi'  })}</NTableCell>
             <NTableCell padding="none" align="right">{row.total_price}/-</NTableCell>
             <NTableCell padding="none" align="right">{
                                   row.status_code===ORDER_STATUS_NOT_PAID?"NOT_PAID":
                                        row.status_code===ORDER_STATUS_PAID?"PAID": 
                                        row.status_code===ORDER_STATUS_SHIPPED?"SHIPPED":  
                                        row.status_code===ORDER_STATUS_DISPUTE?"DISPUTE": 
                                        row.status_code===ORDER_STATUS_FAILED?"FAILED":
                                        row.status_code===ORDER_STATUS_COMPLETED?"COMPLETE":
                                        row.status_code===ORDER_STATUS_CLIENT_NOT_PAID?"PENDING":
                                        row.status_code===ORDER_STATUS_CLIENT_PAID?"SHIPPING":
                                        row.status_code===ORDER_STATUS_CLIENT_SHIPPED?"RECEIVING"
                                        :"N.A"}</NTableCell>
             <NTableCell padding="none" align="right" style={{width:"300px"}}><div style={{ justifyContent:'center', alignItems:'center'}}>{
              row.status_code===ORDER_STATUS_NOT_PAID?"This order has not been paid for. Please pay now.":
              row.status_code===ORDER_STATUS_PAID?"Waiting for seller to confirm and ship your order. Will refund if no response is received from seller on 1/2/2002.":
              row.status_code===ORDER_STATUS_SHIPPED?"Seller has shipped your order,you can open a dispute for this order before 1/2/32000, if no dispute is opened payment will be made to the seller.":
              row.status_code===ORDER_STATUS_DISPUTE?"There is a dispute associated with this order, open order to respond.":
              row.status_code===ORDER_STATUS_FAILED?"This order has failed to complete, check reasons buy vieweing the order.":
              row.status_code===ORDER_STATUS_COMPLETED?"Order was completed successfully":
              row.status_code===ORDER_STATUS_CLIENT_NOT_PAID?"Waiting for buyer to pay for this order, will cancel automatically if not paid for.":
              row.status_code===ORDER_STATUS_CLIENT_PAID?"Buyer has paid for this order kindly ship the items requested.":
              row.status_code===ORDER_STATUS_CLIENT_SHIPPED?"Waiting for buyer to confirm goods received, once confirmed payment will received without disputes.":
              "N.A"}</div></NTableCell>
             <NTableCell padding="none" align="center"><ColorButtonGreen
             onClick={() => {  set_current_order_id(row.id); set_modal_page(2); }}
             disabled={
             row.status_code===ORDER_STATUS_FAILED
            } sx={{ width:'90%',color:"white",backgroundColor:row.status_code===ORDER_STATUS_PAID||row.status_code===ORDER_STATUS_CLIENT_SHIPPED?"purple":row.status_code===ORDER_STATUS_CLIENT_PAID||row.status_code===ORDER_STATUS_NOT_PAID||row.status_code===ORDER_STATUS_DISPUTE||row.status_code===ORDER_STATUS_SHIPPED?"blue":"orange",
            animation: (row.status_code===ORDER_STATUS_NOT_PAID||row.status_code===ORDER_STATUS_PAID||row.status_code===ORDER_STATUS_SHIPPED||row.status_code===ORDER_STATUS_CLIENT_PAID||row.status_code===ORDER_STATUS_DISPUTE)?`${blink} 1s linear infinite`:"",
            }}>{
                                    row.status_code===ORDER_STATUS_NOT_PAID?"Pay Now ?":
                                    row.status_code===ORDER_STATUS_PAID?"Waiting":
                                    row.status_code===ORDER_STATUS_SHIPPED?"Shipped":
                                    row.status_code===ORDER_STATUS_DISPUTE?"Respond":
                                    row.status_code===ORDER_STATUS_FAILED?"Completed":
                                    row.status_code===ORDER_STATUS_COMPLETED?"Completed":
                                    row.status_code===ORDER_STATUS_CLIENT_NOT_PAID?"Waiting":
                                    row.status_code===ORDER_STATUS_CLIENT_PAID?"Ship?":
                                    row.status_code===ORDER_STATUS_CLIENT_SHIPPED?"Waiting":
                                        "N.A"
            }</ColorButtonGreen></NTableCell>
            
           </StyledTableRow >

          ))}
          
        </TableBody>
      </Table>
    </TableContainer>
    <CustomTablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={this.state.orders.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
    </div>
    <MDBFooter bgColor='dark' className='text-center text-white text-lg-left'>
        <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          &copy; {new Date().getFullYear()} Copyright:{' '}
          <a className='text-white' href='https://yotakumarket.com/'>
            yotakumarket.com
          </a>
        </div>
      </MDBFooter>
    </div>
  );
}

}

export default compose(
    withRouter
  )(Orders);