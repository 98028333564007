import React from 'react';
import TraderTile from './TraderTile';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class TraderCatalog extends  React.Component<any, any> {
    constructor(props) {
        super(props);
    
      }
      
    render() {
        return(
          this.props.traders===null?
          <div>Error no traders to display!</div>
          :this.props.traders.length>0?
        <Row xs={1} md={1} className="g-3">
        {this.props.traders.map((current_item, index) => {
                                            return (
                                              <Col key={index} style={{paddingBottom: 30}}>
                                                {/* ID IS NOT NECESSART HERE */}
                                                <TraderTile trader={current_item}></TraderTile>
                                                  </Col>
                                            )
                                        })} 
        </Row>:<p><h4 style={{color:"red"}}>No traders have joined yet!</h4></p>);
        
    }
}
export default TraderCatalog ;
